import { Box, Grid, Button, Divider } from '@mui/material';
import { Thermostat } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import useAxios from 'hooks/useAxios';
import numeral from 'numeral'
import { setInfo } from 'slices/infoSlice';
import OilPriceDetail from './oilPriceDetail';

export default function OilPrice() {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const userId = useSelector(state => state.user.id)
	const { multiple } = useAxios()
	const [page, setPage] = useState("initial")
	const [allJobs, setAllJobs] = useState([])

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/driver/getOilCalByDriver", params: { id: userId }, config: { signal: controller.signal } },
		])
		if (result[0].status === 200) {
			const data = result[0].data.data.records || []
			setAllJobs(data)
		}
	}

	useEffect(() => {
		if (userId && page === "initial") {
			const controller = new AbortController();
			fetchData(controller)
			return () => {
				controller.abort()
			}
		}
	}, [page])

	useEffect(() => {
		if (userId) {
			const controller = new AbortController();
			fetchData(controller)
			return () => {
				controller.abort()
			}
		} else {
			navigate("/driver-portal")
		}
	}, [])

	const goToOilPriceDetail = (index) => {
		const data = allJobs[index]
		dispatch(setInfo({ selectedJob: data }))
		setPage("oilPrice")
	}

	return page === "initial" ? (
		<Box className="!p-2" >
			{allJobs.length > 0 ? allJobs.map((data, i) =>
				<div key={"ReviewJob-" + i}>
					<Grid container spacing={1} columns={12} alignItems="center" className="driver_grid_lg">
						<Grid item xs={12} className="driver_highlight">
							<div>{data.no}</div>
						</Grid>
					</Grid>
					<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_lg">
						<Grid item xs={4} className="driver_head">
							<div>ทะเบียนรถ</div>
						</Grid>
						<Grid item xs={8} className="driver_body">
							<div className="" >{data.truckLicense}</div>
						</Grid>
					</Grid>
					<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_lg">
						<Grid item xs={4} className="driver_head">
							<div>คนขับรถ</div>
						</Grid>
						<Grid item xs={8} className="driver_body">
							<div className="" >{data.driverName}</div>
						</Grid>
					</Grid>
					<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_lg">
						<Grid item xs={4} className="driver_head">
							<div>วันที่เข้ารับสินค้า</div>
						</Grid>
						<Grid item xs={8} className="driver_body">
							<div className="" >{data.etdTime}</div>
						</Grid>
					</Grid>
					<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_lg">
						<Grid item xs={4} className="driver_head">
							<div>วันที่ส่งสินค้า</div>
						</Grid>
						<Grid item xs={8} className="driver_body">
							<div className="" >{data.etaTime}</div>
						</Grid>
					</Grid>
					<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_lg">
						<Grid item xs={3.8} className="driver_head">
							<div>อุณหภูมิ</div>
						</Grid>
						<Grid item xs={8.2}>
							<div className="driver_temperature">
								<Thermostat
									className={`mr-1 ${data.lowestTemp < -10
										? 'text-cyan-600'
										: data.lowestTemp >= -10 && data.lowestTemp < 0
											? 'text-cyan-300'
											: data.lowestTemp >= 0 && data.lowestTemp < 25
												? 'text-gray-400'
												: 'text-orange-400'
										}`}
								/>
								<span className={`${data.lowestTemp < 0 ? 'text-cyan-600' : ''} `}>{data.lowestTemp > 0 ? '+' : ''}{data.lowestTemp}</span>
								{data.lowestTemp ? ' °c' : '-'}
							</div>
						</Grid>
					</Grid>
					<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_lg">
						<Grid item xs={4} className="driver_head">
							<div>ค่าใช้จ่าย</div>
						</Grid>
						<Grid item xs={8} className="driver_body">
							<div className="" >{numeral(data.amount).format('0,0.00')}</div>
						</Grid>
					</Grid>
					<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_lg">
						<Grid item xs={4} className="driver_head">
							<div>สถานะ</div>
						</Grid>
						<Grid item xs={8} className="driver_body">
							<div className="" >{data.status}</div>
						</Grid>
					</Grid>
					<Grid container spacing={0.5} columns={12} alignItems="center" className="driver_grid_lg">
						<Grid item xs={4} className="driver_head">
							<div>หมายเหตุ</div>
						</Grid>
						<Grid item xs={8} className="driver_body">
							<div className="" >{data.remark || '-'}</div>
						</Grid>
					</Grid>
					<Grid item xs={12} className="mt-1 border-solid rounded-lg border-1 border-gray-600 p-2">
						<Grid container alignItems="center" spacing={1} columns={12} >
							<Grid item xs={4} className="driver_head">
								<div>บริษัทขนส่ง</div>
							</Grid>
							<Grid item xs={8} className="driver_body">
								<div className="" >{data.transportCompany}</div>
							</Grid>
						</Grid>
						<Grid container alignItems="center" spacing={1} columns={12} >
							<Grid item xs={4} className="driver_head">
								<div>ประเภทรถ</div>
							</Grid>
							<Grid item xs={8} className="driver_body">
								<div className="" >{data.vehicleTypeName}</div>
							</Grid>
						</Grid>
						<Grid container alignItems="center" spacing={1} columns={12} >
							<Grid item xs={4} className="driver_head">
								<div>เส้นทาง</div>
							</Grid>
							<Grid item xs={8} className="driver_body">
								<div className="" >{data.route}</div>
							</Grid>
						</Grid>
					</Grid>
					<Grid className="py-2 text-center" alignItems={"center"} >
						<Button fullWidth variant="contained" className="!bg-bpTheme-buttonSubmit driver_button" onClick={() => goToOilPriceDetail(i)}>เพิ่มรายการเติมน้ำมัน</Button>
					</Grid>
					<Divider variant="contained" className="driver_divider"></Divider>
				</div>
			) : <div className="driver_no_data">ไม่มีข้อมูลใบงานให้กรอกค่าใช้จ่าย</div>}
		</Box>
	) : <OilPriceDetail setPage={setPage} />
}