import { Box, Chip } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import Avatar from 'components/Avatar'
import { setInfo, clearInfo } from 'slices/infoSlice'
import { warpUpFailResponse } from 'utilities/utils'
import useDataHandler from 'hooks/useDataHandler'

export default function User() {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const { save } = useDataHandler()
	const info = useSelector(state => state.info.ctx || {})
	const [mode, setMode] = useState("initial")
	const { post, del, multiple, get } = useAxios();
	const { confirmPopup } = usePopup();
	const [allEmployee, setAllEmployee] = useState([]);
	const [allRole, setAllRole] = useState([]);
	const [allUser, setAllUser] = useState([]);
	const [reset, setReset] = useState(false);
	const roleUser = useSelector(state => state.user.roleName)

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/user/getAllUser", config: { signal: controller.signal } },
			{ method: "get", url: "/role/getAllActiveRole", config: { signal: controller.signal } }
		])

		if (result[0].status === 200) {
			setAllUser(result[0].data.data || [])
		}
		if (result[1].status === 200) {
			setAllRole(result[1].data.data.records.map(data => ({ label: data.name, value: data.id })) || [])
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	const customTableField = useCallback((text) => text ? <Chip label={text} /> : null, [])

	const customTableProfileField = useCallback((key, data) => <Avatar className="!m-0" prefix={"user/profile"} target={key} name={data.username} />, [])

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				setMode("initial")
			}
		})
	}, [])

	const inputForm = [
		{
			rowData: [
				{
					type: "text",
					required: true,
					props: {
						id: "username",
						label: "ชื่อผู้ใช้",
						variant: "outlined"
					}
				},
				mode === "create" || (mode === "edit" && reset) ? {
					type: "password",
					required: true,
					props: {
						id: "password",
						label: "รหัสผ่าน",
						variant: "outlined",
						showGenerateButton: true
					}
				} : {
					type: "button",
					props: {
						label: "รีเซ็ทรหัสผ่าน",
						onClick: () => {
							setReset(true)
						},
						variant: "text"
					}
				}
			]
		}, {
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "employeeId",
						label: "พนักงาน",
						options: allEmployee,
						onOpen: async () => {
							const result = await get("/employee/getAllAvailableEmployee", { id: info.id })
							if (result.status === 200) {
								setAllEmployee(result.data.data.records.map(data => ({ label: `${data.firstName} ${data.lastName}`, value: data.id })) || [])
							}
						}
					}
				},
				{
					type: "dropdown",
					required: true,
					props: {
						id: "roleId",
						label: "หน้าที่",
						options: allRole,
						notNull: true
					}
				}
			]
		}, {
			rowData: [
				mode === "edit" && {
					type: "dropdown",
					props: {
						id: "active",
						label: "สถานะ",
						options: [
							{ label: "ใช้งาน", value: true },
							{ label: "ไม่ใช้งาน", value: false }
						],
					}
				}, {}
			]
		}, {
			rowData: [
				{
					type: "button",
					props: {
						label: "บันทึก",
						onClick: () => {
							saveData()
						},
						className: "!bg-bpTheme-buttonSubmit"
					}
				}
			]
		}
	]
	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'username',
							label: 'ชื่อผู้ใช้',
							fields: ["username"]
						},
						{
							id: 'employeeName',
							label: 'ชื่อพนักงาน',
							fields: ["employeeName"]
						},
						{
							id: 'roleId',
							label: 'หน้าที่',
							fields: ["roleName"],
							custom: customTableField
						},
						{
							id: 'profile',
							label: 'รูปโปรไฟล์',
							fields: ["profile"],
							custom: customTableProfileField
						},
						{
							id: 'active',
							label: 'สถานะ',
							fields: ["active"],
							valuesMap: [{ label: "ใช้งาน", value: true }, { label: "ไม่ใช้งาน", value: false }]

						}
					],
					rows: allUser,
					size: "small",
					showDeleteButton: roleUser === "Owner",
					title: "ข้อมูลผู้ใช้",
					showSystemFields: true,
					id: "userTable",
					onClick: async (e) => {
						const result = await get("/employee/getAllAvailableEmployee", { id: e.id })
						if (result.status === 200) {
							setAllEmployee(result.data.data.records.map(data => ({ label: `${data.firstName} ${data.lastName}`, value: data.id })) || [])
						}
						setMode("edit")
						setReset(false)
						dispatch(setInfo(e))
					},
					onDelete: (e, onSuccess) => {
						confirmPopup({
							onSubmit: async () => {
								const result = await del("/user/deleteUser", { ids: e.map(data => data.id).join(',') })
								if (result.status === 200) {
									enqueueSnackbar('ลบสำเร็จ', { variant: "success" })
									dispatch(clearInfo())
									await fetchData(new AbortController())
									onSuccess()
								} else {
									enqueueSnackbar('ลบไม่สำเร็จ', { variant: "error" })
								}
							}
						})
					},
					controller: (paging) => get("/user/getAllUser", { paging }),
					searchFields: [
						{ label: "ชื่อผู้ใช้", value: "username" },
						{ label: "หน้าที่", value: "roleName" },
						{ label: "สถานะ", value: "active" }
					]
				}
			}]
		}, {
			noCol: true,
			rowData: [
				{
					type: "button",
					props: {
						label: "เพิ่มใหม่",
						onClick: () => {
							setMode("create")
							dispatch(clearInfo())
						}
					}
				}
			]
		}
	], [allUser])

	const saveData = useCallback(() => {
		save({
			data: info,
			onSubmit: async () => {
				const data = normalizeData(info)
				let response
				if (mode === "create") {
					response = await post("/user/createUser", { ...data, active: true })
				} else if (mode === "edit") {
					response = await post("/user/updateUser", data)
				}
				if (response.status === 200) {
					setMode("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info, mode])

	return (
		<Box>
			<Panel data={table}></Panel>
			<Modal open={mode === "create" || mode === "edit"} onClose={onCloseModal} className="form-modal">
				<Form title="ผู้ใช้" name="user-form" data={inputForm}></Form>
			</Modal>
		</Box>
	)
}
