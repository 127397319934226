import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useAxios from 'hooks/useAxios';
import { format } from 'date-fns';
import { useSnackbar } from 'notistack';
import styled from 'styled-components';
import { handleGeolocationError, handleUnsupportedGeolocation, toBuddhistYear } from 'utilities/utils';
import usePopup from 'hooks/usePopup';

const ClockInOut = () => {
    const dispatch = useDispatch();
    const userId = useSelector(state => state.user.id);
    const { get, post } = useAxios();
    const { enqueueSnackbar } = useSnackbar();
    const { confirmPopup } = usePopup()
    const [currentDate, setCurrentDate] = useState(format(toBuddhistYear(new Date()), 'dd/MM/ddd'));
    const [currentTime, setCurrentTime] = useState(format(new Date(), 'HH:mm:ss'));
    const [clockIn, setClockIn] = useState(null);
    const [clockOut, setClockOut] = useState(null);
    const [withinRange, setWithinRange] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [type, setType] = useState('clock-in'); // or 'clock-out'

    const targetLocation = { latitude: process.env.REACT_APP_COMPANY_LAT, longitude: process.env.REACT_APP_COMPANY_LNG };
    const maxDistance = 50;

    useEffect(() => {
        const controller = new AbortController();
        fetchData(controller);
        const counting = setInterval(() => {
            setCurrentDate(format(toBuddhistYear(new Date()), 'dd/MM/ddd'))
            setCurrentTime(format(new Date(), 'HH:mm:ss'));
        }, 1000);

        try {
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition(
                    position => {
                        calculateDistance(position.coords.latitude, position.coords.longitude);
                    },
                    error => {
                        const errorMsg = handleGeolocationError(error);
                        setHasError(true);
                        setErrorMsg(errorMsg);
                    },
                    { enableHighAccuracy: true }
                );
            } else {
                const errorMsg = handleUnsupportedGeolocation();
                setHasError(true);
                setErrorMsg(errorMsg);
            }
        } catch (e) {
            setHasError(true);
            setErrorMsg(`Error fetching location: ${e.message}`);
        }

        return () => {
            clearInterval(counting);
            controller.abort();
        };
    }, []);

    const fetchData = async (controller) => {
        const result = await get('/user/getLastClockIn', {}, { signal: controller.signal });
        if (result.status === 200) {
            const data = result.data.data || {}
            setClockIn(data?.clockInTime);
            setClockOut(data?.clockOutTime);
            setType(data?.clockInTime && !data?.clockOutTime ? "clock-out" : "clock-in")
        }
    };

    const calculateDistance = (userLat, userLng) => {
        const { latitude: targetLat, longitude: targetLng } = targetLocation;
        const R = 6371e3; // Earth radius in meters
        const φ1 = (userLat * Math.PI) / 180;
        const φ2 = (targetLat * Math.PI) / 180;
        const Δφ = ((targetLat - userLat) * Math.PI) / 180;
        const Δλ = ((targetLng - userLng) * Math.PI) / 180;

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const distance = R * c;
        setWithinRange(distance < maxDistance);
        if (distance > maxDistance) {
            setHasError(true);
            setErrorMsg(`เนื่องจากคุณอยู่นอกระยะที่สามารถ${type === "clock-in" ? "เข้างาน" : "ออกงาน"}ได้ ระยะห่างของคุณคือ ${(distance / 1000).toFixed(2)} กิโลเมตร จะต้องอยู่ภายในรัศมี ${maxDistance} เมตร`);
        } else {
            setHasError(false);
            setErrorMsg('');
        }
    };

    const handleClockInOut = () => {
        const now = format(new Date(), 'dd/MM/yyyy');
        if (clockOut && format(new Date(clockOut), 'dd/MM/yyyy') === now) {
            confirmPopup({
                content: "คุณได้ออกงานวันนี้แล้ว ต้องการบันทึกเข้างานอีกครั้งหรือไม่",
                onSubmit: submitData
            })
        } else {
            submitData()
        }
    };

    const submitData = (async () => {
        const response = await post('/user/clockInOut', { type });
        if (response.status === 200) {
            enqueueSnackbar(`${type === 'clock-in' ? 'เข้างาน' : 'ออกงาน'}สำเร็จ`, { variant: 'success' });
            fetchData(new AbortController());
        } else {
            enqueueSnackbar(`เกิดข้อผิดพลาดระหว่าง${type === 'clock-in' ? 'เข้างาน' : 'ออกงาน'}`, { variant: 'error' });
        }
    })

    return (
        <Box p={3} bgcolor="white" marginTop={2} borderRadius={2} boxShadow={2} maxWidth={400} mx="auto">

            {
                clockIn && <Typography variant="h6" textAlign="center" gutterBottom>
                    เข้างานล่าสุด: {format(toBuddhistYear(new Date(clockIn)), 'dd/MM/yyyy HH:mm')}
                </Typography>
            }
            {
                clockOut && <Typography variant="h6" textAlign="center" gutterBottom>
                    ออกงานล่าสุด: {format(toBuddhistYear(new Date(clockOut)), 'dd/MM/yyyy HH:mm')}
                </Typography>
            }

            <Box textAlign="center" my={2}>
                <Typography variant="h6" textAlign="center" gutterBottom>
                    {currentDate}
                </Typography>
                <Typography variant="h3">{currentTime}</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={handleClockInOut}
                    disabled={!withinRange}
                    sx={{ mt: 2 }}
                >
                    {type === 'clock-in' ? 'เข้างาน' : 'ออกงาน'}
                </Button>
            </Box>

            {hasError && (
                <Typography variant="body1" color="error" textAlign="center" mt={2}>
                    {errorMsg}
                </Typography>
            )}
        </Box>
    );
};

export default ClockInOut;
