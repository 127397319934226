
import { Box, Grid, Button, Divider, FormControlLabel, Checkbox } from '@mui/material';
import { Cancel, PhotoCameraBack } from '@mui/icons-material';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setInfo } from 'slices/infoSlice';
import styled from 'styled-components';
import ProgressBar from '../components/progressBar';
import { DRIVER_PAGE_TAKE_SIGN } from 'helper/DataFactory';
import ShowTime from '../components/showTime';
import { useSnackbar } from 'notistack';

const TextFormat = styled.div`
		font-family: "Roboto","Helvetica","Arial",sans-serif;
`
export default function TakeCamera({ type, onPageChange }) {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const info = useSelector(state => state.info.ctx || {})
	const inputRefByCustomer = useRef(null);
	const inputRefByAdmin = useRef(null);

	const showCamera = (refName) => {
		if (refName === "customer") {
			inputRefByCustomer.current.value = null;
			inputRefByCustomer.current.click();
		} else if (refName === "admin") {
			inputRefByAdmin.current.value = null;
			inputRefByAdmin.current.click();
		}
	}

	const goNext = () => {
		if (info[type === "customer" ? "imageUrlCustomer" : "imageUrlAdmin"]?.length) {
			onPageChange(DRIVER_PAGE_TAKE_SIGN)
		} else {
			enqueueSnackbar('กรุณาเพิ่มรูป', {
				variant: "error", anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				}
			})
		}
	}

	const handleFileChange = async (e, dataType) => {
		const name = dataType === "customer" ? "imageUrlCustomer" : "imageUrlAdmin";
		const currentImages = info?.[name] ? [...info[name]] : [];
		const images = Array.from(e.target.files || [])
		dispatch(setInfo({ [name]: [...currentImages, ...images] }));
	};

	const handleImageDelete = (index, dataType) => {
		const nameType = dataType === "customer" ? "imageUrlCustomer" : "imageUrlAdmin";
		const newArr = info?.[nameType] ? [...info[nameType]] : [];
		newArr.splice(index, 1)
		dispatch(setInfo({ [nameType]: newArr }))
	}

	const handleChange = (event) => {
		dispatch(setInfo({
			[event.target.name]: event.target.checked
		}))
	};

	return (
		<Box>
			<ProgressBar active={2} />
			<div className="mt-1 !text-lg font-bold text-center">
				ถ่ายรูป
			</div>
			<Grid container columns={12} alignItems="center" className="text-base bg-gray-500 text-white px-2">
				<Grid item xs={7}>
					หมายเลขงาน: <br />
					<span>{info?.bills?.map(bill => bill.no).join(', ')}</span>
				</Grid>
				<ShowTime />
			</Grid>
			{
				type === "pickup" ? <TextFormat className="flex flex-row justify-between mx-2 w-full">
					<div className="font-bold self-center">สภาพสินค้า</div>
					<div>
						<FormControlLabel
							label="สินค้าชำรุดและเสียหาย"
							control={<Checkbox
								name="damaged"
								value={info.damaged || false}
								checked={info.damaged || false}
								onChange={handleChange}
							/>}
						/>
					</div>
				</TextFormat> : null
			}

			{/* <Collapse className="pb-0" label={"รูปภาพ สำหรับลูกค้า"} defaultCollapse={true}>
				<div className="flex flex-col gap-2 items-center m-2">
					{info?.imageUrlCustomer?.map((e, i) => (
						<div key={"imageByCustomer" + i} className="relative">
							<img className="w-full rounded-md" src={URL.createObjectURL(e)} alt="Uploaded image" />
							<button className="absolute top-0 right-0 text-orange-800" onClick={() => handleImageDelete(i, "customer")} >
								<Cancel />
							</button>
						</div>
					))}
				</div>
				<div className="m-2 text-center">
					<Button variant="contained" onClick={() => showCamera("customer")} className="driver_button">
						<PhotoCameraBack className="mr-2" />เพิ่มรูป สำหรับลูกค้า
					</Button>
					<input ref={inputRefByCustomer} type="file" accept="image/*" onChange={(e) => handleFileChange(e, "customer")} multiple={true} hidden />
				</div>
			</Collapse> */}
			{/* <Collapse className="pb-0" label={"รูปภาพ"} defaultCollapse={true}>
				<div className="flex flex-col gap-2 items-center m-2">
					{info?.imageUrlAdmin?.map((e, i) => (
						<div key={"imageByAdmin" + i} className="relative">
							<img className="w-full rounded-md" src={URL.createObjectURL(e)} alt="Uploaded image" />
							<button className="absolute top-0 right-0 text-orange-800" onClick={() => handleImageDelete(i, "admin")} >
								<Cancel />
							</button>
						</div>
					))}
				</div>
				<div className="m-2 text-center">
					<Button variant="contained" onClick={() => showCamera("admin")} className="driver_button">
						<PhotoCameraBack className="mr-2" />เพิ่มรูป
					</Button>
					<input ref={inputRefByAdmin} type="file" accept="image/*" onChange={(e) => handleFileChange(e, "admin")} multiple={true} hidden />
				</div>
			</Collapse> */}

			<div className="flex flex-col gap-2 items-center m-2">
				{info?.imageUrlAdmin?.map((e, i) => (
					<div key={"imageByAdmin" + i} className="relative">
						<img className="w-full rounded-md" src={URL.createObjectURL(e)} alt="Uploaded image" />
						<button className="absolute top-0 right-0 text-orange-800" onClick={() => handleImageDelete(i, "admin")} >
							<Cancel />
						</button>
					</div>
				))}
			</div>
			<div className="m-2 text-center">
				<Button variant="contained" onClick={() => showCamera("admin")} className="driver_button">
					<PhotoCameraBack className="mr-2" />เพิ่มรูป
				</Button>
				<input ref={inputRefByAdmin} type="file" accept="image/*" onChange={(e) => handleFileChange(e, "admin")} multiple={true} hidden />
			</div>
			<Divider variant="contained" className="driver_divider"></Divider>
			<div className="py-2 px-1 text-center" >
				<Button variant="contained" className="!bg-bpTheme-buttonSubmit w-[95%] driver_button" onClick={goNext}>ดำเนินการต่อ</Button>
			</div>
		</Box>
	)
}