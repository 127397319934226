import { Autocomplete as MUIAutoComplete, Box, TextField } from '@mui/material'
import React, { useCallback, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setInfoById, setInfo } from 'slices/infoSlice'
import { extractInfo } from 'utilities/utils'
import { matchSorter } from 'match-sorter';
import Button from 'components/Button'
import AddIcon from '@mui/icons-material/Add';

export default function AutoComplete({ parentId, id, className = "", label, placeholder, options = [], relateId, filterOptions, onChange, ListboxComponent, disablePortal = true, required, freeSolo, onAddEvent, sort, debug, allowDuplicate = false, ...other }) {
    const defaultOption = { label: "", value: "", hidden: true }
    const [_options, setOptions] = useState([defaultOption, ...(options || [])])
    const value = useSelector(state => extractInfo(state, parentId, id))
    const relatedValue = useSelector(state => extractInfo(state, parentId, relateId))
    const prevValue = useRef(null)
    const dispatch = useDispatch()
    const handleChange = (value) => {
        debug && console.log('handleChange: ', value)
        if (value !== prevValue.current) {
            if (parentId) {
                dispatch(setInfoById({ id: parentId, payload: { [id]: value } }))
            } else {
                dispatch(setInfo({ [id]: value }))
            }
            if (onChange) {
                onChange(value, _options.find(e => e.value === value))
            }
        }
        prevValue.current = value
    }
    const [selectedValue, setSelectedValue] = useState(null);
    const [inputValue, setInputValue] = useState('');

    const getOptionLabel = useCallback((option) => option ? option.label : "", [])
    const _filterOptions = (fieldTypeList, { inputValue }) => {
        debug && console.log(fieldTypeList)
        const a = fieldTypeList.filter(e => !e.hidden)
        const b = filterOptions ? a.filter(filterOptions) : a

        debug && console.log(b)
        return ListboxComponent || sort ? matchSorter(b, inputValue, { keys: ['label'], threshold: matchSorter.rankings.STRING_CASE_ACRONYM }) : b.filter(opt => (!inputValue || opt.label?.toLowerCase().includes(inputValue?.toLowerCase())))
    }
    const isOptionEqualToValue = useCallback((option, _value) => option?.value === _value?.value, [])

    const handleMouseDown = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        const removeDups = Array.from(new Map(options.map(option => [allowDuplicate ? option.value : option.label, option])).values())
        debug && console.log(removeDups, options)
        setOptions([defaultOption, ...removeDups])
    }, [options])

    useEffect(() => {
        if (value || value === 0) {
            setSelectedValue(_options.find(e => e.value === value) || null)
        } else {
            setSelectedValue(null)
        }
    }, [_options])

    useEffect(() => {
        const filterOtps = filterOptions ? _options.filter(filterOptions) : _options
        if (relateId && !filterOtps.some(e => e.value === value)) {
            handleChange(null)
        }
    }, [relatedValue])

    useEffect(() => {
        debug && console.log('value: ', value)
        if (selectedValue?.value != value && (_options.some(e => e.value === value) || !value)) {
            if (value || value === 0) {
                setSelectedValue(_options.find(e => e.value === value))
            } else {
                setSelectedValue(null)
            }
        }
        prevValue.current = value
    }, [value])

    return (
        <Box className="flex">
            <MUIAutoComplete
                className={`bg-white ${className}`}
                disablePortal={disablePortal}
                id={id}
                value={selectedValue}
                onChange={(event, newInputValue) => {
                    debug && console.log('onChange: ', newInputValue)
                    setSelectedValue(newInputValue)
                    handleChange(newInputValue?.value);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                    debug && console.log('onInputChange: ', newInputValue)
                    if (freeSolo) {
                        handleChange(newInputValue)
                    }
                    setInputValue(newInputValue);
                }}
                clearOnBlur={!freeSolo}
                getOptionLabel={getOptionLabel}
                filterOptions={_filterOptions}
                isOptionEqualToValue={isOptionEqualToValue}
                ListboxComponent={ListboxComponent}
                options={_options}
                sx={{ width: "100%" }}
                renderInput={(params) => <TextField {...params} placeholder={placeholder} label={label} required={required} />}
                {...other}
            />
            {
                onAddEvent && <Button
                    sx={{ marginLeft: "5px" }}
                    className="hover:bg-gray-200"
                    onClick={onAddEvent}
                    onMouseDown={handleMouseDown}
                    variant="text"
                >
                    <AddIcon sx={{ color: "rgba(0, 0, 0, 0.6)" }} />
                </Button>
            }
        </Box>
    )
}
