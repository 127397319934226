import { Box, Typography } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo, clearInfo, setInfoById } from 'slices/infoSlice'
import { normalizeData } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import AdvanceSearch from 'components/AdvanceSearch'
import numeral from 'numeral'
import { STATUS_INVOICE_DENIED, STATUS_INVOICE_APPROVE, STATUS_INVOICE_WAITING, STATUS_INVOICE_CANCELED, STATUS_INVOICE_PAID } from 'helper/DataFactory'
import DownloadIcon from '@mui/icons-material/Download';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { warpUpFailResponse } from 'utilities/utils'
import useDataHandler from 'hooks/useDataHandler'
import { memoizedSelectAdvanceInfo } from 'redux/memorize'
import Invoices from './invoices'
import { format } from 'date-fns'

export default function Invoice() {
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar();
	const { save } = useDataHandler()
	const info = useSelector(state => state.info.ctx)
	const { post, multiple, get, del, download, upload } = useAxios();
	const mode = useState("initial") //main form
	const mode2 = useState("initial") //bill
	const { confirmPopup } = usePopup();
	const [allInvoice, setAllInvoice] = useState([]);
	const [allCustomer, setAllCustomer] = useState([]);
	const [allCustomerAdd, setAllCustomerAdd] = useState([]);
	const paging = useState({})
	const searchInfo = useSelector(memoizedSelectAdvanceInfo)
	const total = info.orders?.reduce((a, v) => a + v?.amount, 0)
	const roleUser = useSelector(state => state.user.roleName)
	const lockedStatus = [STATUS_INVOICE_APPROVE, STATUS_INVOICE_CANCELED, STATUS_INVOICE_PAID]

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/invoice/getAllInvoice", config: { signal: controller.signal } },
			{ method: "get", url: "/customer/getAllActiveCustomer", config: { signal: controller.signal } },
		])
		if (result[0].status === 200) {
			const data = result[0].data.data || []
			setAllInvoice(data)
		}
		if (result[1].status === 200) {
			const data = result[1].data.data.records || []
			setAllCustomer(data.map(e => ({ label: (e.customerName ? `${e.customerName} / ` : "") + e.name, value: e.id, data: e })))
		}
	}

	useEffect(() => {
		if (info.invoiceDate && (info.terms || info.terms === 0)) {
			const dueDate = new Date(info.invoiceDate)
			dueDate.setDate(dueDate.getDate() + Number(info.terms))
			dispatch(setInfo({ dueDate: format(dueDate, 'yyyy-MM-dd') }))
		} else {
			dispatch(setInfo({ dueDate: null }))
		}
	}, [info.invoiceDate, info.terms])

	const fetchBillingLocation = async controller => {
		const result = await get("/location/getAllBillingLocationByCustomer", { customerId: info.customerId }, { signal: controller.signal })
		if (result.status === 200) {
			const data = result.data.data || []
			setAllCustomerAdd(data.map(e => ({ label: `${e.addressTH || "-"} ต.${e.district || "-"} อ.${e.amphoe || "-"} จ.${e.province || "-"} ${e.zipcode || "-"}`, value: e.id })))

			if (mode[0] === "create" && data.some(e => e.defaultAD)) {
				dispatch(setInfo({ customerBillingLocId: data.filter(e => e.defaultAD)[0].id }))
			}
		}
	}

	useEffect(() => {
		const controller = new AbortController();
		fetchData(controller)
		return () => {
			controller.abort()
		}
	}, [])

	useEffect(() => {
		if (info.customerId) {
			const controller = new AbortController();
			const customer = allCustomer.find(e => e.value == info.customerId)?.data || {}
			dispatch(setInfo({ tel: customer.tel || null, taxId: customer.taxAccNo, remark: customer.remark }))
			fetchBillingLocation(controller)
			return () => {
				controller.abort()
			}
		} else {
			dispatch(setInfo({ customerBillingLocId: "" }))
			setTimeout(() => {
				setAllCustomerAdd([])
			}, 100)
		}
	}, [info.customerId])

	const checkFiles = (e, data) => data.files ? <CheckIcon /> : <ClearIcon />

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				mode[1]("initial")
			}
		})
	}, [])

	const inputForm = [
		{
			rowData: [
				{
					type: "text",
					required: true,
					props: {
						id: "no",
						label: "INV NO.",
						readOnly: lockedStatus.includes(info.status)
					}
				},
				{
					type: "text",
					props: {
						id: "poNo",
						label: "PO. NO.",
						readOnly: lockedStatus.includes(info.status)
					}
				},
				{
					type: "date",
					required: true,
					props: {
						id: "invoiceDate",
						label: "Invoice date",
						readOnly: lockedStatus.includes(info.status)
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "customerId",
						label: "ชื่อลูกค้า",
						options: allCustomer,
						readOnly: mode[0] === "edit" || lockedStatus.includes(info.status),
						onChange: (e) => {
							if (!e) {
								dispatch(setInfo({ orders: [], taxId: null, tel: null, terms: null }))
							}
						}
					}
				},
				{
					type: "display",
					props: {
						id: "taxId",
						label: "TAX ID",
					}
				},
				{
					type: "display",
					props: {
						id: "tel",
						label: "เบอร์โทร",
						readOnly: lockedStatus.includes(info.status)
					},
				},
			]
		},
		{
			rowData: [
				{
					type: "autocomplete",
					props: {
						id: "customerBillingLocId",
						label: "ที่อยู่ออกบิลของบริษัท",
						options: allCustomerAdd,
						readOnly: lockedStatus.includes(info.status)
					}
				},
				{},
				{}
			]
		},
		{
			rowData: [
				{
					type: "number",
					required: true,
					props: {
						id: "terms",
						label: "Credit terms",
						readOnly: lockedStatus.includes(info.status)
					},
					md: lockedStatus.includes(info.status) ? 2 : 4
				},
				lockedStatus.includes(info.status) && {
					type: "date",
					required: info.status === STATUS_INVOICE_APPROVE,
					props: {
						id: "paidDate",
						label: "วันที่จ่าย",
						readOnly: [STATUS_INVOICE_PAID, STATUS_INVOICE_CANCELED].includes(info.status)
					},
					md: lockedStatus.includes(info.status) ? 2 : 4
				},
				{
					type: "display",
					props: {
						id: "dueDate",
						label: "วันที่ครบกำหนดชำระ",
						type: "date"
					}
				},
			]
		},
		{
			rowData: info.status === STATUS_INVOICE_DENIED && roleUser === "Owner" ? [
				{
					type: "text",
					props: {
						id: "remark",
						label: "หมายเหตุ",
						multiline: true,
						maxRows: 4,
						readOnly: lockedStatus.includes(info.status)
					},
					md: 8
				},
				{
					type: "dropdown",
					props: {
						id: "newStatus",
						label: "สถานะ",
						options: [
							{ label: STATUS_INVOICE_DENIED, value: STATUS_INVOICE_DENIED },
							{ label: STATUS_INVOICE_WAITING, value: STATUS_INVOICE_WAITING },
						],
						notNull: true,
						readOnly: lockedStatus.includes(info.status)
					},
					md: 4
				}
			] : [
				{
					type: "text",
					props: {
						id: "remark",
						label: "หมายเหตุ",
						multiline: true,
						maxRows: 4,
						readOnly: lockedStatus.includes(info.status)
					}
				}
			]
		},
		{
			isCollapse: true,
			label: "รายการใบแจ้งหนี้",
			collapseData: [
				{
					rowData: [
						{
							type: "dataTable",
							props: {
								columns: [
									{
										id: 'detail',
										label: 'รายละเอียด',
										fields: ["detail"]
									},
									{
										id: 'dateRange',
										label: 'วันที่',
										fields: ["dateRange"],
									},
									{
										id: 'vehicleTypeName',
										label: 'ชนิดรถ',
										fields: ["vehicleTypeName"],
									},
									{
										id: 'qty',
										label: 'จน.',
										fields: ["qty"],
										type: "number"
									},
									{
										id: 'tripPrice',
										label: 'ราคา/เที่ยว',
										fields: ["tripPrice"],
										type: "number"
									},
									{
										id: 'amount',
										label: 'มูลค่า',
										fields: ["amount"],
										type: "number"
									},
								],
								collapse: {
									columns: [
										{
											id: 'pickDate',
											label: 'วัน/เดือน/ปี',
											fields: ["pickDate"],
											type: "date"
										},
										{
											id: 'route',
											label: 'ต้นทาง - ปลายทาง',
											fields: ["pickupAddress", "shippingAddress"],
											spliter: " - "
										},
										{
											id: 'amount',
											label: 'จำนวนเงิน',
											fields: ["amount"],
											type: "number"
										},
										{
											id: 'vehicleTypeName',
											label: 'ชนิด',
											fields: ["vehicleTypeName"],
										},
										{
											id: 'carNo',
											label: 'ทะเบียนรถ',
											fields: ["carNo"],
										},
									],
									field: "invoiceOrders",
								},
								id: "selectedOrders",
								rows: info.orders,
								tableContainerClassName: "max-h-[30vh]",
								multiSelect: !lockedStatus.includes(info.status),
								onClick: !lockedStatus.includes(info.status) ? (e) => {
									dispatch(setInfoById({ id: "orders", payload: e }))
									mode2[1]("edit")
								} : null,
								showDeleteButton: true,
								onDelete: (e, onSuccess) => {
									const ids = e.map(e => e.id) || []
									const removedOrders = info.orders.filter(e => !ids.includes(e.id))
									dispatch(setInfo({ orders: removedOrders }))
									onSuccess()
								},
								size: "small"
							}
						}
					]
				},
				{
					rowData: [
						!lockedStatus.includes(info.status) && {
							type: "button",
							props: {
								label: "เพิ่มรายการ",
								onClick: () => {
									if (info.customerId) {
										mode2[1]("create")
									} else {
										enqueueSnackbar('กรุณาเลือกลูกค้า', { variant: "error" })
									}
								}
							}
						},
						{
							type: "custom",
							align: "right",
							component:
								<Typography variant="body2" gutterBottom className='!pr-4'>
									ราคารวม : &nbsp;&nbsp;&nbsp; {numeral(total).format('0,0[.]00')}
								</Typography>
						}
					]
				},
			]
		},
		{
			isCollapse: true,
			label: "อัปโหลดเอกสาร",
			collapseData: [
				{
					rowData: [
						{
							type: "list",
							props: {
								id: "fileItems",
								mininum: 0,
								showDuplicate: mode[0] === "create",
								data: [
									{
										rowData: [
											{
												type: "text",
												props: {
													id: "name",
													label: "ชื่อไฟล์",
												},
												md: 4
											},
											{
												type: "upload",
												required: true,
												props: {
													id: "filePath",
													label: "แนบไฟล์",
													maxFiles: 1,
												},
												md: 8
											}
										]
									}
								],
								readOnly: lockedStatus.includes(info.status)
							}
						},
					]
				},
			]
		},
		{
			noCol: true,
			rowData: [
				!lockedStatus.includes(info.status) && {
					type: "button",
					props: {
						label: "บันทึก",
						onClick: () => {
							saveData()
						},
						className: "!bg-bpTheme-buttonSubmit"
					}
				},
				mode[0] === "edit" && info.status === STATUS_INVOICE_APPROVE && roleUser === "Owner" && {
					type: "button",
					props: {
						className: "!ml-2",
						label: "Mark as paid",
						onClick: () => {
							paidInvoice()
						}
					}
				},
				mode[0] === "edit" && {
					type: "button",
					props: {
						className: "!ml-2",
						label: "พิมพ์ใบวางบิล/ใบแจ้งหนี้",
						onClick: async () => {
							await download("/doc/getInvoiceReport", { id: info.id })
						}
					}
				},
				mode[0] === "edit" && {
					type: "button",
					props: {
						className: "!ml-2",
						label: "พิมพ์ใบวางบิล/ใบแจ้งหนี้ (Excel)",
						onClick: async () => {
							await download("/doc/getInvoiceExcel", { id: info.id })
						}
					}
				},
				mode[0] === "edit" && {
					type: "button",
					props: {
						className: "!ml-2",
						label: "พิมพ์ใบกำกับภาษี/ใบเสร็จรับเงิน",
						onClick: async () => {
							await download("/doc/getReceiptReport", { id: info.id })
						}
					}
				},
			]
		}
	]

	const search = async (data) => {
		const response = await get("/invoice/getAllInvoice", { search: data, paging: paging[0] })
		if (response.status === 200) {
			const data = response.data.data || []
			setAllInvoice(data)
		}
	}

	const searchFields = [
		{
			type: "date",
			props: {
				label: "ตั้งแต่วันที่",
				id: "startDate",
				maxDate: searchInfo.toDate,
			}
		},
		{
			type: "date",
			props: {
				label: "ถึงวันที่",
				id: "toDate",
				minDate: searchInfo.startDate,
			}
		},
		{
			type: "text",
			props: {
				label: "เส้นทาง",
				id: "route",
			}
		},
		{
			type: "text",
			props: {
				label: "เลขที่อินวอยซ์",
				id: "invoiceNo",
			}
		},
	]

	const table = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'no',
							label: 'เลขที่ใบแจ้งหนี้',
							fields: ["no"]
						},
						{
							id: 'name',
							label: 'ชื่อลูกค้า',
							fields: ["name"]
						},
						{
							id: 'invoiceDate',
							label: 'ณ วันที่',
							fields: ["invoiceDate"],
							type: 'date'
						},
						{
							id: 'total',
							label: 'ยอดรวม',
							fields: ["total"],
							type: "number"
						},
						{
							id: 'status',
							label: 'สถานะ',
							fields: ["status"]
						},
						{
							id: 'files',
							label: 'แนบไฟล์แล้ว',
							fields: ["files"],
							custom: checkFiles
						},
					],
					rows: allInvoice,
					size: "small",
					showDeleteButton: roleUser === "Owner",
					customToolbar: {
						component: AdvanceSearch,
						props: {
							handleSearch: search,
							components: searchFields
						}
					},
					title: "รายการใบแจ้งหนี้",
					showSystemFields: true,
					onClick: async (e) => {
						dispatch(clearInfo())
						mode[1]("edit")
						const result = await get("/invoice/getInvoiceById", { id: e.id })
						if (result.status === 200) {
							dispatch(setInfo({ ...result.data.data, newStatus: result.data.data.status }))
						}
					},
					onDelete: (e, onSuccess) => {
						confirmPopup({
							onSubmit: async () => {
								const result = await del("/invoice/deleteInvoice", { ids: e.map(data => data.id).join(',') })
								if (result.status === 200) {
									enqueueSnackbar('ลบสำเร็จ', { variant: "success" })
									dispatch(clearInfo())
									await fetchData(new AbortController())
									onSuccess()
								} else {
									enqueueSnackbar('ลบไม่สำเร็จ', { variant: "error" })
								}
							}
						})
					},
					controller: (paging) => get("/invoice/getAllInvoice", { paging }),
					state: { paging },
				}
			}]
		}, {
			noCol: true,
			rowData: [
				{
					type: "button",
					props: {
						label: "เพิ่มใหม่",
						onClick: () => {
							mode[1]("create")
							dispatch(clearInfo())
						}
					}
				},
			]
		}
	], [allInvoice, searchInfo])

	const saveData = useCallback(() => {
		save({
			data: info,
			onSubmit: async () => {
				const data = normalizeData(info)
				let uploadFiles = data?.fileItems?.map(e => Array.isArray(e?.filePath) ? e?.filePath[0] : []) || [];
				let dataFiles = []
				if (uploadFiles.length) {
					const result = await Promise.all(uploadFiles.map(e => upload({ prefix: `admin/invoice`, file: e })))
					let files = data?.fileItems?.map(e => Array.isArray(e?.filePath) ? e?.filePath[0].name : data.fileItems) || null;
					if (result.every(e => e.status === 200 || e.status === 400)) {
						dataFiles = data.fileItems.map((item, i) => {
							let fileItem = Array.isArray(item?.filePath) ? item?.filePath[0].name : item.fileItems || null;
							if (files.includes(fileItem)) {
								return {
									...item,
									fileType: fileItem?.split(".").pop(),
									filePath: result.map(e => e.data.data)[i],
									bucketName: "admin/invoice",
									parentType: "invoice"
								};
							} else {
								return {
									...item,
									fileType: item.fileType || null,
									filePath: item.filePath || null,
									bucketName: item.bucketName || null,
									parentType: "invoice"
								};

							}
						});
					}
				}
				let response
				if (mode[0] === "create") {
					response = await post("/invoice/createInvoice", { ...data, orders: data.orders?.map(({ id, ...rest }) => ({ ...rest })), total, fileItems: dataFiles })
				} else if (mode[0] === "edit") {
					response = await post("/invoice/updateInvoice", { ...data, orders: data.orders?.map(({ id, ...rest }) => rest.recStatus === "new" ? { ...rest } : { id, ...rest }), total, fileItems: dataFiles, status: info.newStatus ? info.newStatus : data.status })
				}
				if (response.status === 200) {
					mode[1]("initial")
					enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}, [info, mode[0]])

	const paidInvoice = useCallback(() => {
		if (info.paidDate) {
			confirmPopup({
				onSubmit: async () => {
					const data = normalizeData(info)
					let response = await post("/invoice/paidInvoice", { ...data })
					if (response.status === 200) {
						mode[1]("initial")
						enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
						fetchData(new AbortController())
					} else {
						enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
					}
				},
				onCancel: () => {
					enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
				}
			})
		} else {
			enqueueSnackbar('กรุณาใส่วันที่จ่าย', { variant: "error" })
		}
	}, [info, mode[0]])

	return (
		<Box>
			<Panel name="table" data={table}></Panel>
			<Modal open={mode[0] === "create" || mode[0] === "edit"} onClose={onCloseModal} className="form-modal">
				<Form title={"ข้อมูลใบแจ้งหนี้ " + (info.no || "")} name="user-form" data={inputForm}></Form>
			</Modal>
			<Invoices mode={mode2} customerId={info.customerId} />
		</Box>
	)
}
