import { Box, Grid, Button, Divider } from '@mui/material';
import { PinDrop } from '@mui/icons-material';
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useAxios from 'hooks/useAxios';
import Avatar from 'components/Avatar';
import { useSnackbar } from 'notistack';
import { warpUpFailResponse } from 'utilities/utils';
import { STATUS_ORDER_ARRIVED_SOURCE, STATUS_ORDER_ARRIVED_DESTINATION } from 'helper/DataFactory';
import { DRIVER_PAGE_REVIEW_ORDER } from 'helper/DataFactory';
import { DRIVER_PAGE_TAKE_CAMERA } from 'helper/DataFactory';
import MileageModal from '../components/mileageModal';
import ProgressBar from '../components/progressBar';
import ShowTime from '../components/showTime';
import { setInfo } from 'slices/infoSlice';

export default function CheckIn({ type, onPageChange }) {
	const dispatch = useDispatch()
	const [currentMap, setCurrentMap] = useState(null)
	const { enqueueSnackbar } = useSnackbar();
	const info = useSelector(state => state.info.ctx || {})
	const { post, get } = useAxios()
	const [latitude, setLatitude] = useState(null)
	const [longitude, setLongitude] = useState(null)
	const [mode, setMode] = useState("initial")

	function getLocation() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(showPosition, () => { }, { enableHighAccuracy: true });
		}
	}
	function showPosition(position) {
		const latitude = position.coords.latitude;
		const longitude = position.coords.longitude;
		setCurrentMap("https://www.google.com/maps?q=" + latitude + "," + longitude + "&z=17&output=embed")
		setLatitude(latitude)
		setLongitude(longitude)
	}

	const fetchData = async controller => {
		const result = await get("/driver/checkIsCheckInOrder", { jobId: info.jobId, locationId: info.locationId, type }, { signal: controller.signal })
		if (result.status === 200) {
			const data = result.data.data
			if (data.isChecked) {
				dispatch(setInfo({ mileageIn: data.currentMile }))
				onPageChange(DRIVER_PAGE_TAKE_CAMERA)
			}
		}
	}

	useEffect(() => {
		getLocation()
		const timerMap = setInterval(() => {
			getLocation()
		}, process.env.REACT_APP_REFRESH_LOCATION_TIME)

		const controller = new AbortController();
		fetchData(controller)

		return () => {
			clearInterval(timerMap)
			controller.abort()
		}
	}, [])

	const goBack = () => {
		onPageChange(DRIVER_PAGE_REVIEW_ORDER)
	}

	const onCloseModal = useCallback(() => {
		setMode("initial")
	}, [])

	const inputMileage = () => {
		setMode("edit")
	}

	const saveData = async () => {
		if (!info.mileageIn) {
			enqueueSnackbar("กรุณากรอกเลขไมล์ปัจจุบัน", {
				variant: "error", anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				}
			})
			return
		}

		let response
		response = await post("/driver/updateCheckIn", {
			jobId: info.jobId,
			locationId: info.locationId,
			mapLat: latitude,
			mapLong: longitude,
			mileage: info.mileageIn,
			vehicleId: info.vehicleId,
			remark: type === "pickup" ? "CheckIn ต้นทาง" : "CheckIn ปลายทาง",
			status: type === "pickup" ? STATUS_ORDER_ARRIVED_SOURCE : STATUS_ORDER_ARRIVED_DESTINATION,
			type
		})
		if (response.status === 200) {
			enqueueSnackbar('บันทึกสำเร็จ', {
				variant: "success", anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				}
			})
			onPageChange(DRIVER_PAGE_TAKE_CAMERA)
		} else {
			enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), {
				variant: "error", anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				}
			})
		}
	}

	return (
		<>
			<Box>
				<ProgressBar active={1} />
				<div className="mt-1 !text-lg font-bold text-center">
					เช็คอิน
				</div>
				<Grid container columns={12} alignItems="center" className="text-base bg-gray-500 text-white px-2">
					<Grid item xs={7}>
						หมายเลขงาน: <br />
						<span>{info?.bills?.map(bill => bill.no).join(', ')}</span>
					</Grid>
					<ShowTime />
				</Grid>
				<Grid container columns={12} className="text-base py-1 bg-gray-200" alignItems="center">
					<Grid item xs={3} className="text-center ">
						<PinDrop className="!text-4xl text-orange-400" />
					</Grid>
					<Grid item xs={9} className="font-bold text-orange-400">
						<div >{info.name}</div>
						<div className="text-gray-400 pr-1" >
							{info.address}
						</div>
					</Grid>
				</Grid>
				<div>
					<iframe src={currentMap}
						width="100%" height="270" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
				</div>
				<Grid container spacing={1} columns={12} alignItems="center" className="text-base font-bold px-2 h-[90px]">
					<Grid item xs={3}>
						<Avatar sx={{ width: 65, height: 65 }} prefix={"user/profile"} target={info.profile} name={'name'}></Avatar>
					</Grid>
					<Grid item xs={9}>
						<div>{info.truckLicense}</div>
						<div>{info.driverName}</div>
					</Grid>
				</Grid>
				<Divider variant="contained" className="driver_divider"></Divider>
				<div className="flex justify-between py-2 px-1 text-center" >
					<div className="w-[95%]">
						<Button variant="contained" className="w-[95%] driver_button" onClick={goBack}>ย้อนกลับ</Button>
					</div>
					<div className="w-[95%]">
						<Button variant="contained" className="!bg-bpTheme-buttonSubmit w-[95%] driver_button" onClick={inputMileage}>เช็คอิน</Button>
					</div>
				</div>
			</Box>
			<MileageModal id="mileageIn" open={mode !== "initial"} onCloseModal={onCloseModal} saveData={saveData} okButtonLabel={"เช็คอิน"} />
		</>
	)
}
