import { Box, Grid, Button, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import useAxios from 'hooks/useAxios';
import { clearInfo, setInfo } from 'slices/infoSlice';
import numeral from 'numeral'
import DisplayInformation from '../components/displayInformation';
import { DRIVER_PAGE_CHOOSE_LOCATION } from 'helper/DataFactory';
import { useSnackbar } from 'notistack';

export default function ChooseJob({ type, onPageChange }) {
	const userId = useSelector(state => state.user.id)
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()
	const { get } = useAxios(false)
	const { post } = useAxios()
	const [allJobs, setAllJobs] = useState([])

	const fetchData = async controller => {
		const result = await get("/driver/getLoadOrder", { type }, { signal: controller.signal })
		if (result.status === 200) {
			const data = result.data.data.records || []
			setAllJobs(data)
		}
	}

	const swapDriver = async (jobId) => {
		const response = await post("/driver/swapDriver", { id: jobId })
		if (response.status === 200) {
			fetchData(new AbortController())
		} else {
			enqueueSnackbar('บันทึกไม่สำเร็จ', {
				variant: "error", anchorOrigin: { vertical: 'top', horizontal: 'center', }
			})
		}
	}

	const submitData = (data) => {
		dispatch(setInfo({ ...data }))
		onPageChange(DRIVER_PAGE_CHOOSE_LOCATION)
	}

	useEffect(() => {
		dispatch(clearInfo())
		const controller = new AbortController();
		fetchData(controller)
		const counting = setInterval(() => {
			fetchData(controller)
		}, 10000)
		return () => {
			clearInterval(counting)
			controller.abort()
		}
	}, [])

	return (
		<Box className="!p-2" >
			{allJobs.length > 0 ? allJobs.map((data, i) => {
				const displayData = [
					{
						label: "ทะเบียนรถ",
						data: data.truckLicense
					},
					{
						label: "คนขับรถ",
						data: data.driverName
					},
					{
						label: "คนขับรถ (สำรอง)",
						data: data.driverName2
					},
					{
						label: "พนักงานยกของ",
						data: data.workerName
					},
					{
						label: "พนักงานยกของ (สำรอง)",
						data: data.workerName2
					},
					{
						label: "วันที่เข้ารับสินค้า",
						data: data.etdTime
					},
					{
						label: "วันที่ส่งสินค้า",
						data: data.etaTime
					},
					{
						label: "อุณหภูมิ",
						type: "temperature",
						data: data.lowestTemp
					},
					{
						label: "ค่าใช้จ่าย",
						data: numeral(data.amount).format('0,0.00')
					},
					{
						label: "สถานะ",
						data: data.status
					},
					{
						label: "หมายเหตุ",
						data: data.remark
					},
				]

				const subDisplayData = [
					{
						label: "บริษัทขนส่ง",
						data: data.transportCompany
					},
					{
						label: "ประเภทรถ",
						data: data.vehicleTypeName
					},
					{
						label: "ต้นทาง",
						data: data.departure
					},
					{
						label: "ปลายทาง",
						data: data.destination
					},
				]
				return <div key={"jobs-" + data.no}>
					<Grid container spacing={1} columns={12} alignItems="center" className="driver_grid_lg">
						<Grid item xs={12} className="driver_highlight">
							<div>{data.no}</div>
						</Grid>
					</Grid>
					{
						displayData.map((data, i) => <DisplayInformation key={`data-${i}`} data={data} />)
					}
					<Grid item xs={12} className="mt-1 border-solid rounded-lg border-1 border-gray-600 p-2">
						{
							subDisplayData.map((data, i) => <DisplayInformation key={`sub-data-${i}`} data={data} />)
						}
					</Grid>
					<div className="flex justify-between gap-2 py-2 px-1 text-center" >
						{
							data.driverName2 && data.driverId === userId ?
								<div className="w-full">
									<Button fullWidth variant="contained" className="driver_button" onClick={() => swapDriver(data.jobId)}>สลับคนขับ</Button>
								</div>
								: null
						}
						<div className="w-full">
							<Button fullWidth variant="contained" className="!bg-bpTheme-buttonSubmit driver_button" onClick={() => submitData(data)}>{data.driverId === userId ? type === "pickup" ? "เข้ารับสินค้า" : "เข้าจัดส่งสินค้า" : "ดูรายละเอียด"}</Button>
						</div>
					</div>
					{i < (allJobs.length - 1) && <Divider variant="contained" className="driver_divider" />}
				</div>
			}) : <div className="driver_no_data">{type === "pickup" ? "ไม่มีข้อมูลเข้ารับสินค้า" : "ไม่มีข้อมูลสำหรับจัดส่งสินค้า กรุณาติดต่อผู้ดูแลระบบ"}</div>}
		</Box>
	)
}