import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { useSnackbar } from 'notistack'
import { setInfo, setInfoById, clearInfoById } from 'slices/infoSlice'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import { decentilizedProvinceRes, warpUpFailResponse } from 'utilities/utils'
import { v4 as uuidv4 } from 'uuid'
import useDataHandler from 'hooks/useDataHandler'
import { normalizeData } from 'utilities/validator'
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

export default function Address({ typeId, id, parentMode = [], callback, saveOnBase }) {
	const info = useSelector(state => state.info)
	const dispatch = useDispatch()
	const { enqueueSnackbar } = useSnackbar()
	const { confirmPopup } = usePopup()
	const { save } = useDataHandler()
	const { post, multiple, del } = useAxios()
	const [provinceData, setProvinceData] = useState([])
	const [provinceRes, setProvinceRes] = useState([])
	const modeSub = useState("initial")
	const modalMainId = useMemo(() => uuidv4(), [])
	const modalSubId = useMemo(() => uuidv4(), [])
	const roleUser = useSelector(state => state.user.roleName)

	const fetchData = async controller => {
		const result = await multiple([
			{ method: "get", url: "/province/getAllProvince", config: { signal: controller.signal } },
			{ method: "get", url: "/location/getLocationById", params: { typeId: typeId, id: id }, config: { signal: controller.signal } }
		])
		if (result[0].status === 200) {
			const data = result[0].data.data
			setProvinceRes(data.records)
			const groupedData = decentilizedProvinceRes(data.records)
			setProvinceData(groupedData)
		}
		if (result[1].status === 200) {
			const data = result[1].data.data.records || []
			dispatch(setInfo({ address: data }))
		}
	}

	const fetchDataDefault = async controller => {
		const result = await multiple([
			{ method: "get", url: "/province/getAllProvince", config: { signal: controller.signal } }
		])
		if (result[0].status === 200) {
			const data = result[0].data.data
			setProvinceRes(data.records)
			const groupedData = decentilizedProvinceRes(data.records)
			setProvinceData(groupedData)
		}
	}

	useEffect(() => {
		const controller = new AbortController()
		if (id) {
			fetchData(controller)
		} else {
			fetchDataDefault(controller)
		}
		return () => {
			dispatch(clearInfoById("address"))
			controller.abort()
		}
	}, [id, parentMode[0]])

	const checkAddress = (e, data) => data?.[e] ? <CheckIcon /> : <ClearIcon />

	useEffect(() => {
		if (info.address?.provinceId && provinceRes) {
			const { province, amphoe, district, zipcode } = provinceRes.find(e => info.address.provinceId === e.id)
			dispatch(setInfoById({ id: `address`, payload: { province, amphoe, district, zipcode } }))
		} else {
			dispatch(setInfoById({ id: `address`, payload: { province: null, amphoe: null, district: null, zipcode: null } }))
		}

	}, [info.address?.provinceId])

	const allProvince = useMemo(() => Object.keys(provinceData).map(child => { return { label: child, value: child } }), [provinceData])
	const allAmphoe = useMemo(() => info.address?.province ? Object.keys(provinceData[info.address?.province]).map(child => { return { label: child, value: child } }) : [], [provinceData, info.address?.province])
	const allDistrict = useMemo(() => provinceData.hasOwnProperty(info.address?.province) && provinceData[info.address?.province].hasOwnProperty(info.address?.amphoe) ? Object.keys(provinceData[info.address?.province][info.address?.amphoe]).map(child => { return { label: child, value: child } }) : [], [provinceData, info.address?.province, info.address?.amphoe])

	const onCloseModalMain = useCallback(() => {
		parentMode[1]("initial")
	}, [parentMode])

	const onCloseModalSub = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				dispatch(clearInfoById("address"))
				modeSub[1]("initial")
			}
		})
	}, [modeSub])

	const inputForm = [
		{
			rowData: [
				{
					type: "map",
					required: true,
					className: "pt-0",
					props: {
						id: "location",
						label: "แผนที่"
					}
				}
			]
		},
		{
			rowData: [
				{},
				{
					type: "divider",
					props: {
						label: "ใช้สำหรับ",
						variant: "outlined",
					}, md: 6
				}
			]
		},
		{
			rowData: [
				{
					type: "text",
					required: info.address?.pickup == 1 || info.address?.shipping == 1,
					props: {
						id: "routeName",
						label: "ชื่อหมุด"
					}, md: 6
				},
				{
					type: "checkbox",
					props: {
						id: "pickup",
						label: "รับสินค้า"
					}, md: 1.5
				},
				{
					type: "checkbox",
					props: {
						id: "shipping",
						label: "ส่งสินค้า"
					}, md: 1.5
				},
				{
					type: "checkbox",
					props: {
						id: "document",
						label: "เอกสาร"
					}, md: 1.5
				},
				{
					type: "checkbox",
					props: {
						id: "billing",
						label: "วางบิล"
					}, md: 1.5
				}
			]
		},
		{
			rowData: [
				{
					type: "text",
					required: true,
					props: {
						id: "addressTH",
						label: "ที่อยู่ (ภาษาไทย)",
						multiline: true,
						maxRows: 4
					}
				},
				{
					type: "text",
					props: {
						id: "addressEN",
						label: "ที่อยู่ (ภาษาอังกฤษ)",
						multiline: true,
						maxRows: 4
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "province",
						label: "จังหวัด",
						options: allProvince
					}
				},
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "amphoe",
						label: "อำเภอ",
						options: allAmphoe,
						relateId: "province"
					}
				},
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "district",
						label: "ตำบล",
						options: allDistrict,
						onChange: (value) => {
							if (value) {
								const zipcode = provinceData[info.address?.province][info.address?.amphoe][value].zipcode
								dispatch(setInfoById({ id: `address`, payload: { zipcode } }))
							} else {
								dispatch(setInfoById({ id: `address`, payload: { zipcode: null } }))
							}
						},
						relateId: "amphoe"
					}
				},
				{
					type: "text",
					props: {
						id: "zipcode",
						label: "รหัสไปรษณีย์",
						readOnly: true,
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "text",
					props: {
						id: "contactName",
						label: "ชื่อผู้ติดต่อ",
					}
				},
				{
					type: "text",
					validation: "tel",
					props: {
						id: "contactTel",
						label: "เบอร์ผู้ติดต่อ",
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "text",
					props: {
						id: "remark",
						label: "หมายเหตุ",
					}
				},
				modeSub[0] === "edit" && {
					type: "dropdown",
					props: {
						id: "active",
						label: "สถานะ",
						options: [
							{ label: "ใช้งาน", value: true },
							{ label: "ไม่ใช้งาน", value: false }
						],
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "button",
					props: {
						label: "บันทึก",
						onClick: () => {
							saveData()
						},
						className: "!bg-bpTheme-buttonSubmit"
					}
				}
			]
		}
	]

	const tableA = useMemo(() => [
		{
			rowData: [{
				type: "dataTable",
				props: {
					columns: [
						{
							id: 'no',
							label: 'เลขที่ที่อยู่',
							fields: ["no"]
						},
						{
							id: 'fullAddressTH',
							label: 'ที่อยู่ (ภาษาไทย)',
							fields: ["addressTH", "fullAddress"]
						},
						{
							id: 'routeName',
							label: 'ชื่อหมุด',
							fields: ["routeName"]
						},
						{
							id: 'remark',
							label: 'หมายเหตุ',
							fields: ["remark"]
						},
						{
							id: 'pickup',
							label: 'รับสินค้า',
							fields: ["pickup"],
							custom: (e, data) => checkAddress("pickup", data)
						},
						{
							id: 'shipping',
							label: 'ส่งสินค้า',
							fields: ["shipping"],
							custom: (e, data) => checkAddress("shipping", data)
						},
						{
							id: 'document',
							label: 'เอกสาร',
							fields: ["document"],
							custom: (e, data) => checkAddress("document", data)
						},
						{
							id: 'billing',
							label: 'วางบิล',
							fields: ["billing"],
							custom: (e, data) => checkAddress("billing", data)
						},
						{
							id: 'active',
							label: 'สถานะ',
							fields: ["active"],
							valuesMap: [{ label: "ใช้งาน", value: true },
							{ label: "ไม่ใช้งาน", value: false }]
						}
					],
					id: "selectedAddresses",
					rows: info.ctx.address,
					size: "small",
					spliter: " ",
					showDeleteButton: roleUser === "Owner",
					title: "ข้อมูลที่อยู่",
					onClick: (e) => {
						modeSub[1]("edit")
						dispatch(setInfoById({ id: `address`, payload: { ...e, locationId: e.id } }))
					},
					onDelete: (e, onSuccess) => {
						confirmPopup({
							onSubmit: async () => {
								if (saveOnBase) {
									const result = await del("/location/deleteLocation", { ids: e.map(data => data.id).join(',') })
									if (result.status === 200) {
										enqueueSnackbar('ลบสำเร็จ', { variant: "success" })
										dispatch(clearInfoById("address"))
										fetchData(new AbortController())
										onSuccess()
									} else {
										enqueueSnackbar('ลบไม่สำเร็จ', { variant: "error" })
									}
								} else {
									const ids = e.map(e => e.id) || []
									const filterAddresses = info.ctx.address.filter(e => !ids.includes(e.id))
									dispatch(setInfo({ address: filterAddresses, selectedAddresses: [] }))
								}
							}
						})
					}
				}
			}]
		},
		{
			noCol: true,
			rowData: [
				{
					type: "button",
					props: {
						label: "เพิ่มที่อยู่",
						onClick: () => {
							modeSub[1]("create")
							dispatch(clearInfoById("address"))
						}
					},
				}
			]
		}
	], [info.ctx.address])

	const saveData = () => {
		save({
			data: info.address,
			scope: modalSubId,
			onSubmit: async () => {
				const data = normalizeData(info.address)
				const provinceId = data?.district ? provinceData[data?.province][data?.amphoe][data?.district].id : null
				let response
				let dataLocation = {
					divisionId: typeId === "division" ? id : null,
					customerId: typeId === "customer" ? id : null,
					vendorId: typeId === "vendor" ? id : null,
					addressTH: data.addressTH, addressEN: data.addressEN, provinceId, typeId, routeName: data.routeName,
					pickup: data.pickup, shipping: data.shipping, document: data.document, billing: data.billing,
					location: data.location
				}
				if (modeSub[0] === "edit") {
					dataLocation = {
						id: data.locationId, ...dataLocation, active: data.active
					}
				}
				if (saveOnBase) {
					if (modeSub[0] === "create") {
						response = await post("/location/createLocation", { ...dataLocation })
					} else if (modeSub[0] === "edit") {
						response = await post("/location/updateLocation", { ...dataLocation })
					}
					if (response.status === 200) {
						modeSub[1]("initial")
						enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
						dispatch(clearInfoById("address"))
						fetchData(new AbortController())
						callback && callback()
					} else {
						enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), { variant: "error" })
					}
				} else {
					const addresses = info.ctx.address || []
					if (modeSub[0] === "create") {
						let tempId = uuidv4();
						dispatch(setInfo({
							address: [...addresses, {
								...dataLocation, id: tempId, tempId, active: true, no: "New address",
								fullAddress: "ต." + data.district + " อ." + data.amphoe + " จ." + data.province + " " + data.zipcode
							}]
						}))
					} else if (modeSub[0] === "edit") {
						const editAddress = addresses.map(address => address.id === info.address.id ? {
							...info.address
						} : address)
						dispatch(setInfo({ address: editAddress }))
					}
					dispatch(clearInfoById("address"))
					modeSub[1]("initial")
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', { variant: "info" })
			}
		})
	}

	return (
		parentMode[0] === "modal" ?
			(<Modal id={modalMainId} open={parentMode[0] === "modal"} onClose={onCloseModalMain} className="form-modal">
				<>
					<Panel data={tableA} removeBoxPadding={true}></Panel>
					<Modal id={modalSubId} open={modeSub[0] === "create" || modeSub[0] === "edit"} onClose={onCloseModalSub} className="form-modal">
						<Form id={`address`} title="รายละเอียดข้อมูลที่อยู่" name="user-form" data={inputForm}></Form>
					</Modal>
				</>
			</Modal>)
			:
			(<>
				<Panel data={tableA} removeBoxPadding={true}></Panel>
				<Modal id={modalSubId} open={modeSub[0] === "create" || modeSub[0] === "edit"} onClose={onCloseModalSub} className="form-modal">
					<Form id={`address`} title="รายละเอียดข้อมูลที่อยู่" name="user-form" data={inputForm}></Form>
				</Modal>
			</>)
	)
}
