import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from '@mui/material'
import TabPanel from 'components/TabPanel'
import Schedule from './schedule'
import Summary from './summary'
import { useDispatch } from 'react-redux'
import { setInfo } from 'slices/infoSlice'
import Vehicle from './vehicle'

export default function Planning() {
	const dispatch = useDispatch()
	const [tabs, setTabs] = useState(0)
	useEffect(() => {
		dispatch(setInfo({ filterVehicleTypeId: null, filterStartDate: null, filterEndDate: null }))
	}, [tabs])
	return (
		<>
			<Tabs
				value={tabs}
				onChange={(event, newValue) => setTabs(newValue)}
			>
				<Tab label="ใบงาน" />
				<Tab label="ตารางแพลนงาน" />
				<Tab label="รายการตรวจรถ" />
			</Tabs>
			<TabPanel value={tabs} index={0}>
				<Summary />
			</TabPanel>
			<TabPanel value={tabs} index={1}>
				<Schedule />
			</TabPanel>
			<TabPanel value={tabs} index={2}>
				<Vehicle />
			</TabPanel>
		</>
	)
}
