import React, { useEffect, useState } from 'react'
import { Box, Checkbox, Chip, FormControlLabel, ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import Button from 'components/Button';
import cloneDeep from 'lodash/cloneDeep';
import { clearInfo } from 'slices/infoSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import usePopup from 'hooks/usePopup';
import useAxios from 'hooks/useAxios';
import { warpUpFailResponse } from 'utilities/utils';
import PersonIcon from '@mui/icons-material/Person';
import EllipsisTextComponent from 'components/EllipsisTextComponent';

export default function Image({ type, images = [], callback }) {

	const dispatch = useDispatch()
	const { post } = useAxios();
	const { confirmPopup } = usePopup();
	const { enqueueSnackbar } = useSnackbar();
	const [imgs, setImgs] = useState([])
	const [prevImgs, setPrevImgs] = useState([])
	const [mode, setMode] = useState("initial")
	const templateUser = useSelector(state => state.user.template)

	useEffect(() => {
		const filtered = images?.filter((item) => item?.type?.includes(type)) || []
		setImgs(filtered)
	}, [type, images])

	const openEditMode = () => {
		setPrevImgs(cloneDeep(imgs))
		setMode("edit")
	}

	const cancel = () => {
		setImgs(cloneDeep(prevImgs))
		setMode("initial")
	}

	const save = () => {
		confirmPopup({
			onSubmit: async () => {
				let response = await post("/trackOrder/updateTrackImage", { trackImages: cloneDeep(imgs) })
				if (response.status === 200) {
					enqueueSnackbar('บันทึกสำเร็จ', {
						variant: "success", anchorOrigin: {
							vertical: 'top',
							horizontal: 'center',
						}
					})
					setMode("initial")
					callback && callback()
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), {
						variant: "error", anchorOrigin: {
							vertical: 'top',
							horizontal: 'center',
						}
					})
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', {
					variant: "info", anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					}
				})
			}
		})
	}

	const handleChange = (e, itemId) => {
		const newImgs = imgs?.map(item => (item.id === itemId ? { ...item, show: e?.target?.checked } : item));
		setImgs(newImgs)
	}

	return <>
		<Box className="flex flex-col p-2 grow">
			{templateUser === "admin-portal" && type !== "Temperature" && imgs.length > 0 && mode === "initial" ? <Chip onClick={openEditMode} label="กำหนดรูปสำหรับลูกค้า" color={"primary"} className='cursor-pointer w-fit mb-2' /> : null}
			{mode === "edit" ? <span className='text-sm font-light'>ติ๊กเครื่องหมายถูกเพื่อแสดงให้ลูกค้าเห็น</span> : null}
			<ImageList cols={5} className={mode === "edit" ? 'pt-4' : null}>
				{
					imgs.length > 0 ? imgs.map((item) => item?.type?.includes(type) ? (
						<ImageListItem key={item.img} className="flex flex-row">
							{
								mode === "edit" ?
									<FormControlLabel className="self-start !mr-0 !ml-2 !mt-[-10px]" control={<Checkbox
										value={item.show || false}
										checked={item.show || false}
										onChange={(e) => handleChange(e, item.id)}
									/>} /> :
									null
							}
							<a href={item.img} target="_blank">
								<img src={item.img} alt={item.title} loading="lazy" />
							</a>
							{mode === "initial" ? <ImageListItemBar
								sx={{
									background:
										'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
										'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
								}}
								position="top"
								actionIcon={
									<Box className="flex gap-2 mx-2">
										{
											item.show ? <PersonIcon className='text-white opacity-60' /> : null
										}
										<EllipsisTextComponent className="!text-white" text={item.routeName} />
									</Box>
								}
								actionPosition="left"
							/> : null}
						</ImageListItem>
					) : null) : <Box className="p-5 text-gray-500">{"ไม่มีรูป"}</Box>
				}
			</ImageList>
			{
				mode === "edit" ? <Box className="mt-2">
					<Button onClick={save} className='!mr-2'>บันทึก</Button>
					<Button onClick={cancel} >ยกเลิก</Button></Box> : null
			}
		</Box>
	</>
}
