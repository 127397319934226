import React, { useState } from 'react';
import {
  DRIVER_PAGE_CHOOSE_START_JOB,
  DRIVER_PAGE_TAKE_CAMERA_START_JOB
} from 'helper/DataFactory';
import StartJob from './startJob';
import TakeCameraTempareture from './takeCameraTempareture';

export default function Delivery() {
  const [page, setPage] = useState(DRIVER_PAGE_CHOOSE_START_JOB);

  const onPageChange = (PAGE) => {
    setPage(PAGE);
  };

  switch (page) {
    case DRIVER_PAGE_CHOOSE_START_JOB:
      return <StartJob onPageChange={onPageChange} />;
    case DRIVER_PAGE_TAKE_CAMERA_START_JOB:
      return <TakeCameraTempareture onPageChange={onPageChange} />;
    default:
      return null;
  }
}
