import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { Box, IconButton, Skeleton } from '@mui/material';
import { KeyboardArrowLeft, Logout, Settings } from '@mui/icons-material';
import Menu from 'components/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import ClearIcon from '@mui/icons-material/Clear';
import { SnackbarProvider } from 'notistack';
import Avatar from 'components/Avatar';
import useAxios from 'hooks/useAxios';
import { setUser, setLogin } from 'slices/userSlice';
import format from 'date-fns/format';
import { clearInfo } from 'slices/infoSlice';
import logo from 'assets/images/logo.png'

export default function AdminLayout() {
    const [toggle, setToggle] = useState(false)
    const { get } = useAxios()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();
    const [loaded, setLoaded] = useState(false)
    const [isHide, setIsHide] = useState(false)
    const setToggleSidebar = useCallback(() => {
        setToggle(!toggle)
    }, [toggle])

    const [menu, setMenu] = useState([])

    const showSidebar = useMemo(() => <Sidebar marginLeft={{ xs: `${toggle ? 0 : -300}px`, md: "0" }} isHide={isHide} setIsHide={setIsHide} navigate={navigate} menu={menu} />, [toggle, menu, isHide])

    const fetchData = async controller => {
        const result = await get("/user/getUserInfo", {}, { signal: controller.signal })
        if (result.status === 200) {
            const data = result.data.data
            if (data.userData.template !== "admin-portal") {
                navigate("/" + data.userData.template)
            } else {
                const { menu = [] } = data.appData
                dispatch(setUser({ ...data.userData, ctx: data.userData, appData: data.appData, islogin: true }))
                setMenu(menu)
                setLoaded(true)
            }
        } else if (result.data?.status?.description !== "canceled") {
            navigate("/login")
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        fetchData(controller)
        return () => {
            controller.abort()
        };
    }, []);

    useEffect(() => {
        dispatch(clearInfo());
    }, [location, dispatch]);

    return (
        <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
            <div className="relative">
                <Header setOpenMenu={setToggleSidebar} isOpen={toggle} />
                {
                    loaded ? <Box height="calc(100vh - 64px)" className='flex flex-row w-screen !mt-[64px]'>
                        {showSidebar}
                        <Box className='flex-grow overflow-scrolling-touch overscroll-none !overflow-x-hidden xs:p-0 md:p-5 transition-all scrollbar-thin scrollbar-thumb-bpTheme-scroll scrollbar-track-transparent' overflow={{ xs: toggle ? 'hidden' : 'auto', md: 'auto' }} position='fixed' left={{ xs: toggle ? 300 : 0, md: isHide ? 0 : 300 }} width={{ xs: `100%`, md: isHide ? '100%' : 'calc(100% - 300px)' }} height='inherit'>
                            {toggle ?
                                <Box
                                    sx={{ background: "#00000080", color: '#fff', position: 'fixed', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                    width={{ xs: "100%", md: 0 }}
                                    height={{ xs: "100%", md: 0 }}
                                    onClick={setToggleSidebar}
                                /> : null
                            }
                            <Outlet></Outlet>
                            <Footer></Footer>
                        </Box>
                    </Box> : <Box height="calc(100vh - 64px)" className='flex flex-row w-screen !mt-[64px]'>
                        <Skeleton variant="rectangular" className='md:m-[20px] rounded-md xs:!hidden md:!block' width="300px" height='initial'>
                            <div />
                        </Skeleton>
                        <Skeleton variant="rectangular" className='m-[20px] rounded-md xs:!w-full' width={"calc(100% - 300px)"} height='initial'>
                            <div />
                        </Skeleton>
                    </Box>
                }
            </div>
        </SnackbarProvider >
    );
}

function Footer() {
    return <Box className="p-2" />
}

function Header({ setOpenMenu, isOpen }) {
    const name = useSelector(state => state.user.name)
    const profile = useSelector(state => state.user.profile)
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <Box className='flex justify-between items-center pl-5 pr-5  bg-header h-16 drop-shadow-md fixed w-full top-0'>
            <Box width={{ md: 0 }} position={{ md: "absolute" }} overflow="hidden">
                <IconButton onClick={setOpenMenu}>
                    {
                        isOpen ?
                            <ClearIcon className='text-white' /> :
                            <MenuIcon className='text-white' />
                    }
                </IconButton>
            </Box>
            <Box maxWidth={{ xs: "calc(100% - 60px)", md: "100%" }}>
                <img className='h-[60px]' src={logo} />
            </Box>
            <Box className="flex flex-row">
                <div className="rounded-full my-2 p-2 bg-black bg-opacity-30 text-center xs:hidden md:block w-44">
                    <span className=" text-xs sm:text-sm text-white">
                        {format(currentTime, 'dd MMM yyyy HH:mm:ss')}
                    </span>
                </div>
                <Avatar className='ml-2' prefix={"user/profile"} target={profile} name={name}></Avatar>
            </Box>
        </Box>
    )
}

function Sidebar({ navigate, menu, isHide, setIsHide, ...other }) {
    const name = useSelector(state => state.user.name)
    const { post } = useAxios()
    const dispatch = useDispatch()
    const logout = useCallback(async () => {
        const result = await post("/logout", {}, { ignoreCredential: true })
        if (result.status === 200) {
            dispatch(clearInfo())
            dispatch(setLogin(false))
        }
    }, [])

    const hideMenu = () => {
        setIsHide(!isHide)
    }

    return (
        <>
            <IconButton className={`xs:!hidden md:!inline-flex !absolute z-50 left-2 transition-transform duration-1000 ${isHide ? 'rotate-180' : 'rotate-0'}`} onClick={hideMenu}>
                <KeyboardArrowLeft />
            </IconButton>
            <Box {...other} component="div" flex={`0 0 300px`} style={{ transform: isHide ? 'translateX(-300px)' : 'translateX(0)' }} height="inherit" className='md:pt-5 overflow-x-hidden transition-all flex flex-col'>
                <Menu style={{ height: "calc(100% - 38px)" }} className="overflow-y-auto scrollbar-thin scrollbar-thumb-bpTheme-scroll scrollbar-track-transparent" menu={menu}></Menu>
                <Box className='flex justify-between items-center !text-bpTheme-textColor px-4 min-h-[55px]'>
                    <Settings className='cursor-pointer'></Settings>
                    <span className='overflow-hidden text-ellipsis whitespace-nowrap grow text-center'>{name}</span>
                    <Logout onClick={logout} className='cursor-pointer'></Logout>
                </Box>
            </Box>
        </>
    )
}
