import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'components/Modal'
import Form from 'components/Form'
import { validation } from 'utilities/validator'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import { setInfoById, clearInfoById } from 'slices/infoSlice'
import { v4 as uuidv4 } from 'uuid'
import Address from 'pages/address'

export default function Location({ mode, type }) {
	const dispatch = useDispatch()
	const info = useSelector(state => state.info)
	const mode2 = useState("initial") //province
	const { confirmPopup } = usePopup();
	const { multiple, get } = useAxios()
	const [allUnit, setAllUnit] = useState([])
	const [allLocation, setAllLocation] = useState([])
	const [locationType, setLocationType] = useState("")

	const fetchData = async () => {
		const result = await multiple([
			{ method: "get", url: "/location/getLocationById", params: { typeId: "division", id: info.ctx.senderId } },
			{ method: "get", url: "/unit/getAllActiveUnit" },
		])

		if (result[0].status === 200) {
			const data = result[0].data.data.records.filter(e => type === "pickup" ? e.pickup : e.shipping) || []
			setAllLocation(data.map(e => ({ label: `${e.routeName || `${e.addressTH || "-"} ${e.fullAddress}`}${e.remark ? ` (${e.remark})` : ""}`, value: e.id, data: e })))
			if (!info[locationType]?.locationId && data.some(e => e.defaultAD)) {
				dispatch(setInfoById({ id: locationType, payload: { locationId: [data.filter(e => e.defaultAD)[0].id] } }))
			}
		}
		if (result[1].status === 200) {
			const data = result[1].data.data.records || []
			setAllUnit(data.map(e => ({ label: e.name, value: e.id })))
		}
	}

	useEffect(() => {
		const t = type === "pickup" ? "pickupLocation" : "shippingLocation"
		if (mode[0] === "initial") {
			dispatch(clearInfoById(t))
		} else if (mode[0] === "create" || mode[0] === "edit") {
			setLocationType(t)
			fetchData()
		}
	}, [mode[0]])

	const refreshLocation = async () => {
		const result = await get("/location/getLocationById", { typeId: "division", id: info.ctx.senderId })

		if (result.status === 200) {
			const data = result.data.data.records.filter(e => type === "pickup" ? e.pickup : e.shipping) || []
			setAllLocation(data.map(e => ({ label: `${e.routeName || `${e.addressTH || "-"} ${e.fullAddress}`}${e.remark ? ` (${e.remark})` : ""}`, value: e.id, data: e })))
			if (!info[locationType]?.locationId && data.some(e => e.defaultAD)) {
				dispatch(setInfoById({ id: locationType, payload: { locationId: [data.filter(e => e.defaultAD)[0].id] } }))
			}
		} else {
			setAllLocation([])
		}
	}

	const onCloseModal = useCallback(() => {
		confirmPopup({
			title: "แจ้งเตือน",
			content: "ท่านต้องการยกเลิกการแก้ไขหรือไม่",
			onSubmit: () => {
				mode[1]("initial")
			}
		})
	}, [])

	const saveData = useCallback(() => {
		if (validation(info[locationType], "locationModal")) {
			const locations = info.items[`${locationType}s`] || []
			if (mode[0] === "create") {
				dispatch(setInfoById({
					id: "items",
					payload: {
						[`${locationType}s`]: [
							...locations,
							{
								...info[locationType],
								id: uuidv4(),
								address: `${info[locationType]?.locationId ? allLocation.find(e => e.value === info[locationType]?.locationId)?.label : ""}`,
								routeName: `${info[locationType]?.locationId ? allLocation.find(e => e.value === info[locationType]?.locationId)?.data?.routeName : ""}`,
								recStatus: "new",
								status: "New"
							}
						]
					}
				}))
			} else {
				const newPickupLocation = locations.map(location => location.id === info[locationType]?.id ?
					{
						...info[locationType],
						address: `${info[locationType]?.locationId ? allLocation.find(e => e.value === info[locationType]?.locationId)?.label : ""}`,
						routeName: `${info[locationType]?.locationId ? allLocation.find(e => e.value === info[locationType]?.locationId)?.data?.routeName : ""}`,
					} : location);
				dispatch(setInfoById({
					id: "items",
					payload: { [`${locationType}s`]: newPickupLocation }
				}))
			}
			mode[1]("initial")
		}
	}, [info, locationType, allLocation, allUnit])

	const inputForm = [
		{
			rowData: [
				{
					type: "autocomplete",
					required: true,
					props: {
						id: "locationId",
						label: type === "pickup" ? "สถานที่รับ" : "สถานที่ส่ง",
						options: allLocation,
						onChange: (e, data) => {
							if (data) {
								dispatch(setInfoById({ id: locationType, payload: { remark: data.data.remark } }))
							}
						},
						onAddEvent: () => {
							mode2[1]("modal")
						}
					},
				},
				{
					type: "number",
					props: {
						id: "quantity",
						label: "จำนวน"
					}
				}
			]
		},
		{
			rowData: [
				{
					type: "text",
					props: {
						id: "remark",
						label: "หมายเหตุ",
						multiline: true,
						maxRows: 4
					}
				}
			]
		},
		{
			noCol: true,
			align: "center",
			rowData: [
				{
					type: "button",
					props: {
						label: "ตกลง",
						onClick: saveData,
					}
				}
			]
		}
	]

	return (
		<>
			<Modal id="locationModal" open={mode[0] === "create" || mode[0] === "edit"} onClose={onCloseModal} className="form-modal">
				<Form id={locationType} title={type === "pickup" ? "ข้อมูลจุดรับ" : "ข้อมูลจุดส่ง"} name="user-form" data={inputForm}></Form>
			</Modal>
			{
				mode2[0] === "modal" ?
					<Address typeId={"division"} id={info.ctx.senderId} parentMode={mode2} callback={refreshLocation} saveOnBase={true} />
					:
					null
			}
		</>
	)
}