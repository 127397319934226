import { Box, Grid, Button, Divider } from '@mui/material';
import { Thermostat } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';
import useAxios from 'hooks/useAxios';
import { useSnackbar } from 'notistack';
import { warpUpFailResponse } from 'utilities/utils';
import usePopup from 'hooks/usePopup';
import numeral from 'numeral'
import { STATUS_JOB_ACCEPT_JOB } from 'helper/DataFactory';
import { parse, format } from 'date-fns';
import DisplayInformation from '../components/displayInformation';

export default function AcceptJob() {
	const navigate = useNavigate()
	const { confirmPopup } = usePopup();
	const { enqueueSnackbar } = useSnackbar();
	const userId = useSelector(state => state.user.id)
	const { get, post } = useAxios()
	const [allJobs, setAllJobs] = useState([])

	const fetchData = async controller => {
		const result = await get("/driver/getAcceptJobByDriver", {}, { signal: controller.signal })
		if (result.status === 200) {
			const data = result.data.data.records || []
			setAllJobs(data)
		}
	}

	useEffect(() => {
		if (userId) {
			const controller = new AbortController();
			fetchData(controller)
			return () => {
				controller.abort()
			}
		} else {
			navigate("/driver-portal")
		}
	}, [])

	const saveData = async (data) => {
		confirmPopup({
			onSubmit: async () => {
				let response
				const parsedDate = parse(data.etdDate, 'dd/MM/yyyy', new Date());
				const formattedDate = format(parsedDate, 'yyyy-MM-dd');
				response = await post("/driver/updateAcceptJob", { etdTime: formattedDate, status: STATUS_JOB_ACCEPT_JOB })
				if (response.status === 200) {
					enqueueSnackbar('บันทึกสำเร็จ', {
						variant: "success", anchorOrigin: {
							vertical: 'top',
							horizontal: 'center',
						}
					})
					fetchData(new AbortController())
				} else {
					enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), {
						variant: "error", anchorOrigin: {
							vertical: 'top',
							horizontal: 'center',
						}
					})
				}
			},
			onCancel: () => {
				enqueueSnackbar('ยกเลิกรายการ', {
					variant: "info", anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					}
				})
			}
		})
	}

	return (
		<Box className="!p-2">
			{allJobs.length > 0 ? allJobs.map((data, i) => {
				const displayData = [
					{
						label: "วันที่วิ่งงาน",
						data: data.etdDate
					},
					{
						label: "ทะเบียนรถ",
						data: data.truckLicense
					},
					{
						label: "คนขับรถ",
						data: data.driverName
					},
					{
						label: "เวลารับรถ",
						data: data.takeCarTime
					},
					{
						label: "ค่าใช้จ่าย",
						data: numeral(data.amount).format('0,0.00')
					},
				]

				const subDisplayData = data.jobs?.map(job => [
					{
						label: "เลขที่ใบงาน",
						data: job.no
					},
					{
						label: "ลูกค้า",
						data: job.customerName
					},
					{
						label: "โปรเจค",
						data: job.projectName
					},
					{
						label: "คนขับรถ (สำรอง)",
						data: job.driverName2
					},
					{
						label: "พนักงานยกของ",
						data: job.workerName
					},
					{
						label: "พนักงานยกของ (สำรอง)",
						data: job.workerName2
					},
					{
						label: "เวลารับสินค้า",
						data: job.etdTime
					},
					{
						label: "เส้นทาง",
						data: job.route
					},
					{
						label: "อุณหภูมิ",
						type: "temperature",
						data: data.lowestTemp
					},
					{
						label: "หมายเหตุ",
						data: job.remark
					},
				])
				return <div key={"jobs-" + data.no}>
					<Grid container spacing={1} columns={12} alignItems="center" className="driver_grid_lg">
						<Grid item xs={12} className="driver_highlight">
							<div>{data.no}</div>
						</Grid>
					</Grid>
					{
						displayData.map((data, i) => <DisplayInformation key={`data-${i}`} data={data} />)
					}
					<Grid item xs={12} className="mt-1 border-solid rounded-lg border-1 border-gray-600 text-lg px-4 py-2 mb-2">
						{
							subDisplayData.map((data, i) => {
								return <div key={"acceptJob-" + i}>{
									data.map((data, i) => <DisplayInformation key={`sub-data-${i}`} data={data} />)
								}
									{i < (subDisplayData.length - 1) && <Divider variant="contained" className="driver_divider my-2" />}
								</div>
							})
						}

					</Grid>
					<Grid className="py-2 text-center" alignItems={"center"} >
						<Button fullWidth variant="contained" className="!bg-bpTheme-buttonSubmit driver_button" onClick={() => saveData(data)}>รับใบสั่งปฎิบัติงาน</Button>
					</Grid>
					{i < (allJobs.length - 1) && <Divider variant="contained" className="driver_divider" />}
				</div>
			}) : <div className="driver_no_data">ไม่มีข้อมูลใบสั่งปฎิบัติงาน</div>}
		</Box>
	)
}