import { Box, Grid, Button, Divider, IconButton } from '@mui/material';
import { Adjust, KeyboardArrowLeft, PermIdentity, Phone } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useAxios from 'hooks/useAxios';
import { useDispatch, useSelector } from 'react-redux';
import { setInfo } from 'slices/infoSlice';
import DisplayInformation from '../components/displayInformation';
import { formatCoordinates } from 'utilities/utils';
import { DRIVER_PAGE_REVIEW_ORDER } from 'helper/DataFactory';
import { DRIVER_PAGE_CHOOSE_JOB } from 'helper/DataFactory';

export default function ChooseLocation({ type, onPageChange }) {
	const userId = useSelector(state => state.user.id)
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const info = useSelector(state => state.info.ctx)
	const { get } = useAxios()
	const [allTakeOrder, setAllTakeOrder] = useState([])

	const fetchData = async controller => {
		const result = await get("/driver/getTakeOrderByDriver", { id: info.jobId, type }, { signal: controller.signal })
		if (result.status === 200) {
			const data = result.data.data.records || []
			setAllTakeOrder(data)
		}
	}

	useEffect(() => {
		if (info.jobId) {
			const controller = new AbortController();
			fetchData(controller)
			return () => {
				controller.abort()
			}
		} else {
			navigate("/driver-portal")
		}
	}, [])

	const submitData = async (data) => {
		dispatch(setInfo(data))
		onPageChange(DRIVER_PAGE_REVIEW_ORDER)
	}

	const goBack = () => {
		onPageChange(DRIVER_PAGE_CHOOSE_JOB)
	}

	return (
		<>
			<Box className="mt-2">
				<IconButton onClick={goBack}>
					<KeyboardArrowLeft />
				</IconButton>
			</Box>
			<Box className="!p-2" >
				{allTakeOrder.length > 0 ? allTakeOrder.map((data, i) => {

					const lat = data.lat;
					const lon = data.lng;
					const zoom = 15;

					const latString = formatCoordinates(lat, 'N');
					const lonString = formatCoordinates(lon, 'E');

					const displayData = [
						{
							label: "อุณหภูมิ",
							type: "temperature",
							data: data.lowestTemp
						},
						{
							label: "จำนวนสินค้า",
							data: data.quantity <= 0 ? 'ดูหน้างาน' : data.quantity
						},
						{
							label: "หมายเหตุ",
							data: data.remark
						},
					]

					return <div key={"location-" + i}>
						<Grid container spacing={1} columns={12} className="driver_grid_lg" alignItems="center">
							<Grid item xs={2.5} className="self-start text-center">
								<div className=" font-bold !h-full" >{type === "pickup" ? "รับ" : "ส่ง"}</div>
								<Adjust className="text-orange-400 " />
								<div className="text-orange-400">⋮</div>
							</Grid>
							<Grid item xs={9.5} className="font-bold">
								<a className={!(data.lat || data.lng) ? "cursor-default" : ""} href={data.lat && data.lng ? `https://www.google.com/maps/place/${latString}+${lonString}/@${lat},${lon},${zoom}z` : '#'} target={data.lat && data.lng ? '_blank' : '_self'}>
									<>{data.name}
										<div className="driver_address" >
											{data.address}
										</div>
									</>
								</a>

								<Divider variant="contained" className="my-2"></Divider>
								<div className="flex flex-row text-gray-400 text-base w-full">
									<div className="flex flex-row w-[55%]">
										<div><PermIdentity className="mr-1 !h-5 text-orange-400" /></div>
										<div>{data.contactName}</div>
									</div>
									<div className=" w-[45%]">
										<Phone className="mr-1 !h-5 text-orange-400 cursor-pointer" onClick={() => {
											if (data.contactTel) {
												window.location.href = 'tel:' + data.contactTel
											}
										}} />
										<span>{data.contactTel}</span>
									</div>
								</div>
							</Grid>
						</Grid>
						{
							displayData.map((data, i) => <DisplayInformation key={`data-${i}`} data={data} />)
						}
						<div className="flex justify-between gap-2 py-2 px-1 text-center" >
							{
								data.driverId === userId ?
									<div className="w-full">
										<Button fullWidth variant="contained" disabled={i !== 0} className="!bg-bpTheme-buttonSubmit driver_button" onClick={() => submitData(data)}>{type === "pickup" ? "รับสินค้า" : "จัดส่งสินค้า"}</Button>
									</div>
									: null
							}
						</div>
						{i < (allTakeOrder.length - 1) && <Divider variant="contained" className="driver_divider" />}
					</div>
				}) : <div className="driver_no_data">{type === "pickup" ? "ไม่มีข้อมูลสินค้า" : "ไม่มีข้อมูลสำหรับจัดส่งสินค้า กรุณาติดต่อผู้ดูแลระบบ"}</div>}
			</Box>
		</>
	)
}