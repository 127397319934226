import { Box, Link } from '@mui/material'
import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import useAxios from 'hooks/useAxios'
import { validation, normalizeData } from 'utilities/validator'
import { setUser } from 'slices/userSlice'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { clearInfo } from 'slices/infoSlice'
import logo from 'assets/images/logo.jpeg'
import { useEffect } from 'react'
export default function Login() {
	const info = useSelector(state => state.info.ctx)
	const dispatch = useDispatch()
	const { post } = useAxios()
	const navigate = useNavigate()
	const [errorMsg, setErrorMsg] = useState("")

	useEffect(() => {
		dispatch(clearInfo())
	}, [])

	const inValidUser = () => {
		const requiredFields = document.getElementsByClassName("required")
		Array.prototype.slice.call(requiredFields).map(e => {
			e.classList.add("invalid")
		})
		setErrorMsg("The username or password does not exist.")
	}

	const login = useCallback(async () => {
		if (errorMsg) {
			setErrorMsg("")
			const requiredFields = document.getElementsByClassName("invalid")
			Array.prototype.slice.call(requiredFields).map(e => {
				e.classList.remove("invalid")
			})
		}
		if (validation(info)) {
			const data = normalizeData(info)
			const { username, password } = data
			const result = await post("/login", { username, password }, { ignoreCredential: true })
			if (result.status === 200) {
				const data = result.data.data
				if (data.loginStatus) {
					const token = data.token || null
					dispatch(clearInfo())
					dispatch(setUser({ ...data.userInfo.userData, ctx: data.userInfo.userData, appData: data.userInfo.appData, islogin: true }))
					const expiryDate = new Date();
					expiryDate.setDate(expiryDate.getDate() + 2);
					Cookies.set('actk', token, { secure: true, sameSite: 'strict', expires: expiryDate });
					navigate("/" + data.userInfo.userData.template)
				} else {
					inValidUser()
				}
			} else {
				inValidUser()
			}
		}
	}, [info])

	const inputForm = [
		{
			rowData: [
				{
					type: "custom",
					component: <>
						<div className='d-flex justify-center align-items-center my-6 text-2xl font-bold'>
							Login
						</div>
					</>
				}
			]
		}, {
			rowData: [
				{
					type: "text",
					required: true,
					props: {
						id: "username",
						label: "User name",
						variant: "outlined"
					}
				}
			]
		}, {
			rowData: [
				{
					type: "password",
					required: true,
					props: {
						id: "password",
						label: "Password",
						variant: "outlined"
					}
				}
			]
		},
		errorMsg && {
			rowData: [
				{
					type: "custom",
					component: <div className='text-red-400 text-center'>{errorMsg}</div>
				}
			]
		}
		, {
			rowData: [
				{
					type: "button",
					align: "center",
					className: "mt-5",
					props: {
						label: "SIGN IN",
						onClick: login
					}
				}
			]
		}, {}
	]

	return (
		<Box>
			<div className='flex flex-col overflow-hidden lg:flex-row lg:h-screen items-center'>
				<div className='hidden lg:block lg:w-[60%] text-center'>
					<img src={logo}
						className='object-cover object-center w-screen'
						alt="images" />
				</div>
				<div className='flex flex-col justify-evenly items-center lg:w-[40%]'>
					<Panel name="user-form" data={inputForm}></Panel>
				</div>
			</div>
		</Box>
	)
}
