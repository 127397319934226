import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { faClipboardList, faBox, faTruck, faSquareXmark, faMagnifyingGlass, faListCheck, faTruckFast, faReceipt, faGasPump, faFileInvoice, faClock, faTasks, faTemperature0, faUser } from '@fortawesome/free-solid-svg-icons'
import "./i18n";
import AdminLayout from "./layout/adminLayout";
import DriverLayout from "./layout/driverLayout";
import Login from "./pages/login";
import { useDispatch, useSelector } from "react-redux";
import Notifications from "components/Notifications";
import { Backdrop, CircularProgress } from "@mui/material";
import DriverMenu from "components/DriverMenu";
import NotFound from "pages/notfound";
import AcceptJob from "pages/driverPortal/acceptJob";
import Delivery from "pages/driverPortal/delivery";
import OutStandingOrder from "pages/driverPortal/outStandingOrder";
import ReviewJob from "pages/driverPortal/reviewJob";
import TrackOrder from "pages/driverPortal/trackOrder";
import OilPrice from "pages/driverPortal/oilPrice";
import Attendance from "pages/driverPortal/attendance";
import CorrectFeatures from "pages/driverPortal/correctFeatures";
import ConfirmResend from "pages/driverPortal/confirmResend";
import ClockInOut from './pages/driverPortal/clockinout/index';
import Assignments from "pages/driverPortal/assignments";
import StartJob from "pages/driverPortal/startJob";
import Cookies from "js-cookie";
import { useEffect, useMemo } from "react";
import { clearInfo } from "slices/infoSlice";
import AdminComponents from "modules/adminPortalModules";
import CustomerLayout from './layout/customerLayout/index';
import Order from "pages/order";
import AdminTracking from "pages/adminTracking";
import { faUser as faUserOutline } from "@fortawesome/free-regular-svg-icons";

function App() {
	const popup = useSelector(state => state.popup)
	const appData = useSelector(state => state.user.appData)
	const userData = useSelector(state => state.user)
	const isLoading = useSelector(state => state.isloading)
	const isLogin = useSelector(state => state.user.islogin)
	const dispatch = useDispatch()
	useEffect(() => {
		if (!isLogin) {
			Cookies.remove('actk')
			dispatch(clearInfo())
		}
	}, [isLogin])

	const buildRoutes = (menuItems) => {
		return menuItems.map((menuItem) => {
			if (menuItem.menu) {
				return buildRoutes(menuItem.menu)
			}
			if (menuItem.componentName) {
				const Component = AdminComponents[menuItem.componentName];
				const props = menuItem.componentProps ? JSON.parse(menuItem.componentProps) : {};
				return Component ? (
					<Route
						key={`route-${menuItem.id}`}
						path={menuItem.path}
						element={<Component {...props} />}
					/>
				) : null;
			}
		});
	};

	const menuRoutes = useMemo(() => {
		if (appData) {
			return buildRoutes(appData.menu);
		} else {
			return null;
		}
	}, [appData]);

	return (
		<>
			<div id="pro_wrapper">
				<BrowserRouter>
					<Routes>
						<Route exact path="/" element={<Login />} />
						<Route path="/login" element={<Login />} />
						{
							isLogin ? (
								<>
									<Route path="/admin-portal" element={<AdminLayout />} >
										{
											menuRoutes
										}
										<Route path="*" element={<NotFound />} />
									</Route>
									<Route path="/driver-portal" element={<DriverLayout />} >
										<Route path="" element={<DriverMenu menu={
											userData.positionName === "พนักงานยกสินค้า" ? [
												{
													name: "งานที่ได้รับมอบหมาย",
													path: "assignments",
													icon: faTasks
												},
												{
													name: "ลงเวลาเข้าออกงาน",
													path: "clockinout",
													icon: faClock
												}
											]
												: [
													{
														name: "พขร.ยืนยันรับใบสั่งปฎิบัติงาน",
														path: "acceptJob",
														icon: faClipboardList,
														countGroup: "assignments"
													},
													{
														name: "ลงเวลาเข้าออกงาน",
														path: "attendance",
														icon: faClock
													},
													{
														name: "ถ่ายรูปตั้งอุณหภูมิ",
														path: "startJob",
														icon: faTemperature0,
														countGroup: "startAssignments"
													},
													{
														name: "รับสินค้า",
														path: "pickupOrder",
														icon: faBox,
														countGroup: "pickUpAssignments"
													},
													{
														name: "จัดส่งสินค้า",
														path: "shippingOrder",
														icon: faTruck,
														countGroup: "shippingAssignments"
													},
													{
														name: "งานที่ต้องส่งใหม่",
														path: "outStandingOrder",
														icon: faSquareXmark,
														countGroup: "outstandingAssignments"
													},
													{
														name: "ติดตามสถานะ",
														path: "trackOrder",
														icon: faMagnifyingGlass
													},
													{
														name: "ยืนยันค่าใช้จ่าย",
														path: "costConfirm",
														icon: faReceipt,
														countGroup: "toReviewAssignments"
													},
													{
														name: "ตรวจสอบการทำงาน",
														path: "correctFeatures",
														icon: faListCheck
													}
												]} />} />
									</Route>
									<Route path="/driver-portal" element={<DriverLayout isGeneralTemplate={true} />} >
										<Route path="acceptJob" element={<AcceptJob />} />
										<Route path="assignments" element={<Assignments />} />
										<Route path="startJob" element={<StartJob />} />
										<Route path="pickupOrder" element={<Delivery type={"pickup"} />} />
										<Route path="shippingOrder" element={<Delivery type={"shipping"} />} />

										<Route path="outStandingOrder" element={<OutStandingOrder />} />
										<Route path="confirmResend" element={<ConfirmResend />} />
										<Route path="trackOrder" element={<TrackOrder />} />
										<Route path="costConfirm/oilPrice" element={<OilPrice />} />
										<Route path="costConfirm/additionalPrice" element={<ReviewJob />} />
										<Route path="correctFeatures" element={<CorrectFeatures />} />

										<Route path="clockinout" element={<ClockInOut />} />
										<Route path="attendance" element={<DriverMenu menu={[
											{
												name: "สำหรับคนขับหลัก",
												path: "/driver-portal/attendance/mainDriver",
												icon: faUser,
												className: "fa-flip-horizontal"
											},
											{
												name: "สำหรับคนขับสำรอง",
												path: "/driver-portal/attendance/subDriver/clockinout",
												icon: faUserOutline
											}
										]} />} />
										<Route path="attendance/mainDriver" element={<DriverMenu menu={[
											{
												name: "รับรถ",
												path: "/driver-portal/attendance/mainDriver/clockin",
												icon: faTruck,
												className: "fa-flip-horizontal"
											},
											{
												name: "ส่งรถ",
												path: "/driver-portal/attendance/mainDriver/clockout",
												icon: faTruck
											}
										]} />} />
										<Route path="attendance/mainDriver/clockin" element={<Attendance type="clock-in" />} />
										<Route path="attendance/mainDriver/clockout" element={<Attendance type="clock-out" />} />
										<Route path="attendance/subDriver/clockinout" element={<ClockInOut />} />

										<Route path="costConfirm" element={<DriverMenu menu={[
											{
												name: "ค่าน้ำมัน",
												path: "oilPrice",
												icon: faGasPump
											},
											{
												name: "ยืนยันค่าใช้จ่ายเพิ่มเติม",
												path: "additionalPrice",
												icon: faFileInvoice,
												countGroup: "toReviewAssignments"
											}
										]} />} />
										<Route path="*" element={<NotFound />} />
									</Route>
									<Route path="/customer-portal" element={<CustomerLayout />} >
										<Route path="" element={<Order portal="customer" />} />
										<Route path="order" element={<Order portal="customer" />} />
										<Route path="tracking" element={<AdminTracking portal="customer" />} />
										<Route path="*" element={<NotFound />} />
									</Route>
								</>
							) : <Route path="*" element={<Navigate to="/login" replace />} />
						}
					</Routes>
				</BrowserRouter>
			</div>
			{
				popup.map((e) => <Notifications key={e.id} {...e} />)
			}
			<Backdrop
				sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
				open={isLoading}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
}
export default App;
