import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Panel from 'components/Panel'
import Modal from 'components/Modal'
import { useSnackbar } from 'notistack'
import { setInfo, clearInfo } from 'slices/infoSlice'
import usePopup from 'hooks/usePopup'
import useAxios from 'hooks/useAxios'
import Form from 'components/Form'
import { COMPANY_NAME } from 'helper/DataFactory'
import { IconButton, ImageList, ImageListItem, ImageListItemBar } from '@mui/material'
import { Image } from '@mui/icons-material'
import notfound from 'assets/images/notfound.jpg'

export default function Vehicle() {

    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const { get, multiple } = useAxios();
    const info = useSelector(state => state.info.ctx)
    const page = useState(0)
    const rowsPerPage = useState(15)
    const count = useState(0)
    const paging = useState({})
    const { confirmPopup } = usePopup();
    const [allVehicles, setAllVehicles] = useState([]);
    const [mode, setMode] = useState("initial")
    const [mode2, setMode2] = useState("initial")
    const [files, setFiles] = useState([])
    const fetchData = async controller => {
        const result = await multiple([
            { method: "get", url: "/vehicle/getAllCheckedVehicle", params: { paging: paging[0] }, config: { signal: controller.signal } },
        ])

        if (result[0].status === 200) {
            const vehicles = result[0].data.data || []
            setAllVehicles(vehicles)
        }
    }

    const onCloseModal = useCallback(() => {
        setMode("initial")
    }, [mode])

    const fetchImg = useCallback(async (id) => {
        const result = await get("/vehicle/getVehicleCheckListImg", { attendanceId: id })
        if (result.status === 200) {
            const data = result.data.data.records || []
            setFiles(data)
            setMode2("display")
        }
    }, [])

    const renderCheckedTime = useCallback((text, data) => {
        return <span className={data.checkList?.some(e => e.status === 'damage') ? 'text-red-400' : data.checkList?.some(e => e.status === 'maintain') ? 'text-blue-400' : ''}>{data.checkDateTime}</span>
    }, [])

    const renderFile = useCallback((text, data) => {
        return data.hasAttachment ? <IconButton onClick={() => {
            fetchImg(data.attendanceId)
        }}>
            <Image />
        </IconButton> : null
    }, [])

    const renderDamage = useCallback((text, data) => {
        return <span className={data.checkListHistory?.length && data.checkListHistory[0].checkList?.some(e => e.status === 'damage') ? 'text-red-400' : ''}>{data.checkListHistory?.length ? data.checkListHistory[0].checkList?.some(e => e.status === 'damage') ? 'มีรายการเสียหาย' : data.checkListHistory[0].checkList?.some(e => e.status === 'maintain') ? 'มีรายการส่งซ่อม' : 'ปกติ' : 'ปกติ'}</span>
    }, [])

    const inputForm = [
        {
            rowData: [
                {
                    type: "display",
                    props: {
                        id: "chassisNo",
                        label: "เลขตัวถัง"
                    }
                },
                {
                    type: "display",
                    props: {
                        id: "lcnNo",
                        label: "ป้ายทะเบียน"
                    }
                },
                {
                    type: "display",
                    props: {
                        id: "vehicleTypeName",
                        label: "ประเภทรถ",
                    }
                },
                {
                    type: "display",
                    props: {
                        id: "vendorName",
                        label: "บริษัท"
                    }
                }
            ]
        },
        {
            rowData: [
                {
                    type: "display",
                    props: {
                        id: "model",
                        label: "ยี่ห้อรถ",
                    }
                },
                {
                    type: "display",
                    props: {
                        id: "modelAir",
                        label: "เลขโมเดลแอร์",
                    }
                },
                {
                    type: "display",
                    props: {
                        id: "serialNo",
                        label: "เลขซีเรียล (Serial number)",
                    }
                },
                {
                    type: "display",
                    props: {
                        id: "mileage",
                        label: "เลขไมล์ปัจจุบัน",
                        type: "number"
                    }
                }
            ]
        },
        {
            rowData: [
                {
                    type: "display",
                    props: {
                        id: "registerDate",
                        label: "วันที่จดทะเบียน",
                        type: "date"
                    }
                },
                {
                    type: "display",
                    props: {
                        id: "vehicleAge",
                        label: "อายุรถ",
                    }
                },
                {
                    type: "display",
                    props: {
                        id: "status",
                        label: "สถานะรถ",
                        valuesMap: {
                            active: "ใช้งาน",
                            maintain: "ส่งซ่อม",
                            damage: "เสียหาย"
                        }
                    }
                },
                {}
            ]
        },
        {
            rowData: [
                {
                    type: "dataTable",
                    props: {
                        columns: [
                            {
                                id: 'checkDateTime',
                                label: 'รอบการตรวจ',
                                fields: [],
                                type: "datetime",
                                custom: renderCheckedTime
                            },
                            {
                                id: 'checkedBy',
                                label: 'ตรวจสอบโดย',
                                fields: ["checkedBy"]
                            },
                            {
                                id: 'files',
                                label: 'ไฟล์ประกอบ',
                                fields: [],
                                type: "datetime",
                                align: "right",
                                custom: renderFile
                            }
                        ],
                        collapse: {
                            columns: [
                                {
                                    id: 'name',
                                    label: 'รายการตรวจ',
                                    fields: ["name"]
                                },
                                {
                                    id: 'status',
                                    label: 'สถานะ',
                                    fields: ["status"],
                                    valuesMap: [
                                        { label: "ใช้งาน", value: "active" },
                                        { label: "ส่งซ่อม", value: "maintain" },
                                        { label: "เสียหาย", value: "damage" }
                                    ]
                                },
                            ],
                            field: "checkList"
                        },
                        rows: info.checkListHistory,
                        title: "ประวัติรายการตรวจ",
                        tableContainerClassName: "max-h-[30vh]",
                        size: "small"
                    }
                }
            ]
        }
    ]

    const table = useMemo(() => [
        {
            rowData: [{
                type: "dataTable",
                props: {
                    columns: [
                        {
                            id: 'lcnNo',
                            label: 'ป้ายทะเบียน',
                            fields: ["lcnNo"]
                        },
                        {
                            id: 'vehicleTypeName',
                            label: 'ประเภทรถ',
                            fields: ["vehicleTypeName"]
                        },
                        {
                            id: 'model',
                            label: 'ยี่ห้อรถ',
                            fields: ["model"]
                        },
                        {
                            id: 'vendorName',
                            label: 'บริษัท',
                            fields: ["vendorName"]
                        },
                        {
                            id: 'status',
                            label: 'สถานะรถ',
                            fields: ["status"],
                            valuesMap: [
                                { label: "ใช้งาน", value: "active" },
                                { label: "ส่งซ่อม", value: "maintain" },
                                { label: "หยุดใช้งาน", value: "inactive" }
                            ]
                        },
                        {
                            id: 'statusChecked',
                            label: 'สถานะการตรวจ',
                            fields: [""],
                            custom: renderDamage
                        },
                    ],
                    rows: allVehicles,
                    size: "small",
                    title: "ข้อมูลรถ",
                    showSystemFields: false,
                    state: { page, rowsPerPage, count, paging },
                    onClick: (e) => {
                        dispatch(clearInfo())
                        dispatch(setInfo({ ...e, vendorId: e.vendorId || COMPANY_NAME }))
                        setMode("display")
                    },
                    controller: (paging) => get("/vehicle/getAllCheckedVehicle", { paging }),
                    searchFields: [
                        { label: "ยี่ห้อรถ", value: "model" },
                        { label: "เลขตัวถัง", value: "chassisNo" },
                        { label: "ป้ายทะเบียน", value: "lcnNo" },
                        { label: "ประเภทรถ", value: "vehicleTypeName" },
                        { label: "บริษัทรถร่วม", value: "vendorName" },
                        { label: "สถานะรถ", value: "status" }
                    ]
                }
            }]
        },
    ], [allVehicles])


    useEffect(() => {
        const controller = new AbortController()
        fetchData(controller)
        return () => {
            controller.abort()
        }
    }, [mode])

    return (
        <>
            <Panel data={table}></Panel>
            <Modal open={mode === "display"} onClose={onCloseModal} className="form-modal">
                <Form title={"ข้อมูลรถ " + (info.lcnNo || "")} name="user-form" data={inputForm}></Form>
            </Modal>
            <Modal open={mode2 === "display"} onClose={() => { setMode2("initial") }} className="form-modal">
                <ImageList cols={3} >
                    {
                        files.map((e, index) => {
                            if (!e.url) {
                                return
                            }
                            const isVideo = /\.(mp4|webm|ogg)$/i.test(e.url?.split('?')[0]);
                            const isImage = /\.(jpg|jpeg|png|gif)$/i.test(e.url?.split('?')[0]);

                            return (
                                <>
                                    <ImageListItem key={index}>
                                        {isVideo ? (
                                            <video
                                                src={e.url}
                                                controls
                                                style={{ objectFit: 'cover' }}
                                                alt={e.key}
                                                loading="lazy"
                                            />
                                        ) : isImage ? (
                                            <img
                                                src={e.url}
                                                alt={e.key}
                                                loading="lazy"
                                                style={{ objectFit: 'cover' }}
                                            />
                                        ) : (
                                            <img
                                                src={notfound}
                                                alt="Not found"
                                                loading="lazy"
                                                style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                                            />
                                        )}
                                        <ImageListItemBar
                                            title={e.type === "clock-in" ? "รับรถ" : "ส่งรถ"}
                                        />
                                    </ImageListItem>
                                </>
                            );
                        })
                    }
                </ImageList>
            </Modal>
        </>
    )
}
