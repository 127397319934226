
import { Box, Grid, Button, Divider } from '@mui/material';
import { Cancel, PhotoCameraBack } from '@mui/icons-material';
import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setInfo, clearInfo } from 'slices/infoSlice';
import { useSnackbar } from 'notistack';
import usePopup from 'hooks/usePopup';
import useAxios from 'hooks/useAxios';
import { resizeImage, warpUpFailResponse } from 'utilities/utils';
import { MAX_FILE_SIZE } from 'helper/DataFactory';
import { getSessionUUID } from 'utilities/utils';
import ShowTime from '../components/showTime';
import ProgressModal from '../components/progressModal';
import { DRIVER_PAGE_CHOOSE_START_JOB } from 'helper/DataFactory';
import Number from 'components/Number';

export default function TakeCameraTempareture({ onPageChange }) {
    const dispatch = useDispatch()
    const info = useSelector(state => state.info.ctx || {})
    const { post } = useAxios()
    const { upload } = useAxios(false)
    const { confirmPopup } = usePopup();
    const { enqueueSnackbar } = useSnackbar();
    const inputRef = useRef(null);
    const [progress, setProgress] = useState({ text: "กำลังบีบอัดไฟล์...", current: 0 });
    const [openProgressBar, setOpenProgressBar] = useState(false)

    const showCamera = () => {
        inputRef.current.value = null;
        inputRef.current.click()
    }

    const handleFileChange = async (e) => {
        const newArr = info.temperatureImg ? [...info.temperatureImg] : []
        const files = e.target.files || [];
        const filesArray = Array.from(files);
        dispatch(setInfo({ temperatureImg: [...newArr, ...filesArray] }));
    };

    const handleImageDelete = (index) => {
        const newArr = info.temperatureImg ? [...info.temperatureImg] : []
        newArr.splice(index, 1)
        dispatch(setInfo({ temperatureImg: newArr }))
    }

    const loadFile = async (dataFile, showValue) => {
        const imageUrl = dataFile || [];

        if (!imageUrl.length) {
            return []
        }

        setOpenProgressBar(true);
        setProgress(() => ({ text: "กำลังบีบอัดไฟล์...", current: 0 }));

        let imgs = [];
        for (let i = 0; i < imageUrl.length; i++) {
            const img = imageUrl[i];
            const resizeImg = await resizeImage(img, MAX_FILE_SIZE, (percent) => {
                setProgress((prev) => ({ ...prev, current: ((percent + (100 * i)) / imageUrl.length) }))
            });
            imgs.push(resizeImg);
        }

        setProgress(() => ({ text: "กำลังอัปโหลดไฟล์...", current: 0 }));

        let result = [];
        for (let i = 0; i < imgs.length; i++) {
            const img = imgs[i];
            const uploadResult = await upload({ prefix: `driver/temperature`, file: img });
            setProgress((prev) => ({ ...prev, current: ((i + 1) / imgs.length) * 100 }));
            result.push(uploadResult);
        }

        setOpenProgressBar(false);

        let images = [];
        if (result.every(e => e.status === 200)) {
            images = result.map((e) => ({
                isShow: showValue,
                fileData: e.data.data,
            }));
        }

        return images;
    };

    const goBack = () => {
        dispatch(clearInfo())
		onPageChange(DRIVER_PAGE_CHOOSE_START_JOB)
    }

    const saveData = async () => {
        if (!info.startMile) {
            enqueueSnackbar('กรุณากรอกเลขไมล์เริ่มงาน', {
                variant: "error", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            })
            return
        }
        if (!info.temperatureImg?.length) {
            enqueueSnackbar('กรุณาเพิ่มรูปตั้งอุณหภูมิ', {
                variant: "error", anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }
            })
            return
        }
        confirmPopup({
            onSubmit: async () => {
                try {
                    setOpenProgressBar(true);
                    setProgress(() => ({ text: "กำลังบีบอัดไฟล์...", current: 0 }));

                    const images = await loadFile(info.temperatureImg, false);
                    const sessionUUID = getSessionUUID();
                    let response = await post("/driver/updateStartMile", {
                        jobId: info.jobId,
                        images,
                        imgSessionId: images?.length ? sessionUUID : null,
                        startMile: info.startMile,
                        vehicleId: info.vehicleId
                    })
                    if (response.status === 200) {
                        enqueueSnackbar('บันทึกสำเร็จ', { variant: "success" })
                        dispatch(clearInfo())
                        onPageChange(DRIVER_PAGE_CHOOSE_START_JOB)
                    } else {
                        enqueueSnackbar(warpUpFailResponse(response, 'บันทึกไม่สำเร็จ'), {
                            variant: "error", anchorOrigin: {
                                vertical: 'top',
                                horizontal: 'center',
                            }
                        })
                    }
                } catch (e) {
                    let currentTime = new Date().toLocaleString();
                    alert("ระบบขัดข้อง: " + e + " at " + currentTime);
                }
            },
            onCancel: () => {
                enqueueSnackbar('ยกเลิกรายการ', {
                    variant: "info", anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                    }
                })
            }
        })
    }

    return (
        <>
            <Box>
                <div className="mt-1 !text-lg font-bold text-center">
                    เข้าข้อมูลก่อนเริ่มงาน
                </div>
                <Grid container columns={12} alignItems="center" className="text-base bg-gray-500 text-white px-2">
                    <Grid item xs={7}>
                        หมายเลขงาน: {info?.no}
                    </Grid>
                    <ShowTime />
                </Grid>
                <div className="flex justify-center mt-4 w-full">
                    <Number
                        size="small"
                        id="startMile"
                        label="เลขไมล์เริ่มงาน">
                    </Number>
                </div>
                <div className="flex flex-col gap-2 items-center m-2">
                    {info.temperatureImg?.map((e, i) => (
                        <div key={"temperatureImg" + i} className="relative">
                            <img className="w-full rounded-md" src={URL.createObjectURL(e)} alt="Uploaded image" />
                            <button className="absolute top-0 right-0 text-orange-800" onClick={() => handleImageDelete(i)} >
                                <Cancel />
                            </button>
                        </div>
                    ))}
                </div>
                <div className="m-2 text-center">
                    <Button variant="contained" onClick={showCamera} className="driver_button">
                        <PhotoCameraBack className="mr-2" />เพิ่มรูปตั้งอุณหภูมิ
                    </Button>
                    <input ref={inputRef} type="file" accept="image/*" onChange={handleFileChange} hidden multiple={true} />
                </div>
                <Divider variant="contained" className="driver_divider"></Divider>

                <div className="flex justify-between gap-2 py-2 px-1 text-center" >
                    <div className="w-full">
                        <Button fullWidth variant="contained" className="driver_button" onClick={goBack}>ย้อนกลับ</Button>
                    </div>
                    <div className="w-full">
                        <Button fullWidth variant="contained" className="!bg-bpTheme-buttonSubmit driver_button" onClick={saveData}>ยืนยันข้อมูล</Button>
                    </div>
                </div>
            </Box>
            <ProgressModal open={openProgressBar} progress={progress} />
        </>

    )
}